import Vue from 'vue';
import App from './App.vue'; // Import App.vue
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;

console.log('Starting Vue.js application...');

new Vue({
  vuetify,
  router,
  render: h => h(App) // Render App as the root component
}).$mount('#app');

console.log('Vue.js application mounted.');
