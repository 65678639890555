import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);



export default new Vuetify({
  theme: {
    options: { customProperties: true }, // Enable customProperties
    themes: {
      light: {
        primary: '#323232', // Dark grey
        secondary: '#424242', // Grey
        accent: '#646464', // Grey
        background: '#FFFFFF', // Your desired background color
        // Add your custom color
        customColor: '#FFA500', // Example: Orange for your custom stars
      },
      dark: {
        primary: '#2196F3',
        // Define dark theme colors if needed
        customColor: '#FFA500',
      },
    },
  },
});

