import levenshtein from 'fast-levenshtein';
import MiniSearch from 'minisearch';



export function applyExtractedFilters(perfumes, filters) {
    return perfumes.filter(perfume => {
        const currentYear = new Date().getFullYear();

        // Normalize brands by removing spaces/special characters and comparing the first five letters
           let brandMatch = true;
        if (filters.brand) {
            // Normalize brand strings by removing spaces and special characters, then take the first five characters.
            const normalizedInputBrand = filters.brand.toLowerCase().replace(/[\s&]/g, '').substring(0, 5);
            const normalizedStoredBrand = perfume.brand.toLowerCase().replace(/[\s&]/g, '').substring(0, 5);

            // Calculate the Levenshtein distance allowing a 20% error margin on the actual length of the input brand.
            const brandThreshold = Math.max(1, Math.floor(normalizedInputBrand.length * 0.20));  // Adjusted to consider 20% errors
            const brandDistance = levenshtein.get(normalizedStoredBrand, normalizedInputBrand);

            // Check if the first five characters match or if the distance is within the acceptable threshold.
            brandMatch = normalizedStoredBrand.startsWith(normalizedInputBrand) || brandDistance <= brandThreshold;
        }

        let notesMatch = true;
        if (filters.notes && filters.notes.length) {
            const notesLower = perfume.notes?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            notesMatch = filters.notes_conjunction === 'and' ?
                filters.notes.every(note => notesLower.includes(note.toLowerCase())) :
                filters.notes.some(note => notesLower.includes(note.toLowerCase()));
        }

        let typesMatch = true;
        if (filters.combined_types && filters.combined_types.length) {
            const typesLower = perfume.combined_types?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            typesMatch = filters.types_conjunction === 'and' ?
                filters.combined_types.every(type => typesLower.includes(type.toLowerCase())) :
                filters.combined_types.some(type => typesLower.includes(type.toLowerCase()));
        }


        let priceMatch = !filters.price || filters.price.includes(perfume.price);
        let sillageMatch = !filters.sillage || filters.sillage.includes(perfume.sillage);
        let longevityMatch = !filters.longevity || filters.longevity.includes(perfume.longevity);
        let genderMatch = filters.scentgn ? perfume.scentgn === filters.scentgn : true;

         
        let yearMatch = true;
        if (filters.year) {
            yearMatch = parseInt(perfume.year) === filters.year;
        }
        let afterYearMatch = true;
        if (filters.afterYear) {
            afterYearMatch = parseInt(perfume.year) > filters.afterYear;
        }
        let beforeYearMatch = true;
        if (filters.beforeYear) {
            beforeYearMatch = parseInt(perfume.year) < filters.beforeYear;        
        }
        let rangeYearMatch = true;
        if (filters.rangeYear) {
            const year = parseInt(perfume.year);
            rangeYearMatch = year >= filters.rangeYear[0] && year <= filters.rangeYear[1];
        }
        
        // New, Classic, Legendary filters
        let newMatch = true, classicMatch = true, legendaryMatch = true;

        if (filters.new) {
            newMatch = parseInt(perfume.year) === currentYear - 1;
        }
        if (filters.classic) {
            classicMatch = parseInt(perfume.year) <= currentYear - 25;
        }
        if (filters.legendary) {
            legendaryMatch = parseInt(perfume.year) <= currentYear - 50;
        }
        

        // Combine all checks
        return brandMatch && notesMatch && typesMatch && longevityMatch && sillageMatch &&
               priceMatch && genderMatch && afterYearMatch && beforeYearMatch && rangeYearMatch &&
               newMatch && classicMatch && legendaryMatch && yearMatch;
    });
}




///COMBINED SEARCH QUERY/// (Magic Search, Smart Search, Standard Search)


// Outside the function, declare a variable to hold the last valid results


export function performSearch(searchQuery, perfumes, type) {
    if (!searchQuery || typeof searchQuery !== 'string' || !searchQuery.trim()) {
        //console.log("No search query provided or query is invalid, displaying default dataset.");
        return perfumes; // Immediately return all perfumes if the search query is empty
    }

    // Define a function to normalize text
    function normalizeText(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Removes diacritics (e.g., accents)
    }

    // Check if the query contains any of the types and adjust boost for 'combined_types'
    const types = type;  // Using types from your Vue component or main file
    const queryContainsType = types.some(type => normalizeText(searchQuery).toLowerCase().includes(normalizeText(type).toLowerCase()));
    const combinedTypesBoost = queryContainsType ? 3 : 0.5;

    // Set of common stop words in perfume descriptions
    let stopWords = new Set([
        'and', 'or', 'to', 'in', 'a', 'the', 'la', 'le', 'des', 'du', 'un', 'une',
        /* Additional stop words as needed */
    ]);

    // Function to process terms including handling abbreviations and stop words
    function processTerm(term) {
        term = normalizeText(term).toLowerCase(); // Normalize and convert term to lower case
        term = term.replace(/\b1\b/g, 'one'); // Convert numeric '1' to 'one'
        return stopWords.has(term) ? null : term; // Filter out stop words
    }

    // Map of abbreviations to full brand names
    const brandAbbreviations = {
        'ck': 'calvin klein',
        'no5': 'n°5',
        'no 5': 'n°5',
        'chanel 5': 'n°5',
        'bulgari': 'bvlgari',
        'th': 'tommy hilfiger',
        'jc': 'jimmy choo',
        'ga': 'giorgio armani',
        'd&g': 'dolce & gabbana',
        'ysl': 'yves saint laurent',
        'mj': 'marc jacobs',
        'jpg': 'jean paul gaultier',
        'ch': 'carolina herrera',
        'pr': 'paco rabanne',
        'mm': 'maison margiela',
        'jml': 'jo malone london',
        'jm': 'jo malone',
        'cdg': 'comme des garçons',
        'adp': 'acqua di parma',
        'mfk': 'maison francis kurkdjian',
        'lv': 'louis vuitton',
        'cc': 'clive christian',
        'fm': 'frederic malle',
        'pdm': 'parfums de marly',
        'tf': 'tom ford'
    };

    // Replace abbreviations in the search query
    const queryWords = searchQuery.split(/\s+/);
    const processedQuery = queryWords.map(word => brandAbbreviations[normalizeText(word).toLowerCase()] || word).join(' ');

    // Initialize MiniSearch
    let miniSearch = new MiniSearch({
        fields: ['name', 'brand', 'notes', 'notes_strong', 'combined_types'], // Separate fields for notes
        storeFields: ['name', 'brand', 'notes', 'notes_strong', 'combined_types'],
        processTerm: processTerm, // Use the custom term processing function
        searchOptions: {
            fuzzy: term => term.length <= 3 ? 0 : term.length <= 5 ? 0.20 : term.length <= 9 ? 0.30 : 0.35,
            prefix: term => term.length > 2,
            combineWith: 'AND',
            boost: {
                name: 2.35,
                brand: 12.5,
                notes: 1.5,
                notes_strong: 1.51, // Smaller boost for notes_strong
                combined_types: combinedTypesBoost,  // Dynamic boosting
            },
            weights: {
                fuzzy: 0.06,
                prefix: 0.9
            }
        }
    });

    // Concatenate notes_medium and notes_strong for indexing
    function concatenateNotes(perfume) {
        return `${perfume.notes_medium}, ${perfume.notes_strong}`;
    }        
    
    // Index perfumes with normalized fields
    miniSearch.addAll(perfumes.map(perfume => ({
        id: perfume.id,
        name: normalizeText(perfume.name),
        brand: normalizeText(perfume.brand),
        notes: concatenateNotes(perfume), // Assuming 'notes' field does not need normalization for now
        notes_strong: normalizeText(perfume.notes_strong), // Separate field with lower boost
        combined_types: perfume.combined_types // Assuming 'notes' field does not need normalization for now
    })));

    
    if (searchQuery && searchQuery.trim()) {
        const query = searchQuery.trim();
        const yearRegex = /^\d{4}$/i;
        const afterYearRegex = /^after (\d{4})$/i;
        const beforeYearRegex = /^before (\d{4})$/i;
        const rangeYearRegex = /^(\d{4})\s*-\s*(\d{4})$/i;
        const newRegex = /^new$/i;
        const classicRegex = /^classic$/i;
        const legendaryRegex = /^legendary$/i;

        const yearMatch = query.match(yearRegex);
        const afterYearMatch = query.match(afterYearRegex);
        const beforeYearMatch = query.match(beforeYearRegex);
        const rangeYearMatch = query.match(rangeYearRegex);
        const newMatch = query.match(newRegex);
        const classicMatch = query.match(classicRegex);
        const legendaryMatch = query.match(legendaryRegex);

        if (yearMatch && parseInt(query) >= 1800 && parseInt(query) <= 2080) {
            return perfumes.filter(perfume => parseInt(perfume.year) === parseInt(query));
        } else if (afterYearMatch && parseInt(afterYearMatch[1]) >= 1800 && parseInt(afterYearMatch[1]) <= 2080) {
            return perfumes.filter(perfume => parseInt(perfume.year) > parseInt(afterYearMatch[1]));
        } else if (beforeYearMatch && parseInt(beforeYearMatch[1]) >= 1800 && parseInt(beforeYearMatch[1]) <= 2080) {
            return perfumes.filter(perfume => parseInt(perfume.year) < parseInt(beforeYearMatch[1]));
        } else if (rangeYearMatch) {
            return perfumes.filter(perfume => {
                const year = parseInt(perfume.year);
                return year >= parseInt(rangeYearMatch[1]) && year <= parseInt(rangeYearMatch[2]);
            });
        } else if (newMatch) {
            const currentYear = new Date().getFullYear();
            return perfumes.filter(perfume => parseInt(perfume.year) >= currentYear - 1);
        } else if (classicMatch) {
            const currentYear = new Date().getFullYear();
            return perfumes.filter(perfume => parseInt(perfume.year) <= currentYear - 25);
        } else if (legendaryMatch) {
            const currentYear = new Date().getFullYear();
            return perfumes.filter(perfume => parseInt(perfume.year) <= currentYear - 50);
        } else {
            let miniResults = miniSearch.search(processedQuery);
            miniResults = miniResults.filter(result => result.score > 1);
            if (miniResults.length > 0) {
                return miniResults.map(({ id }) => perfumes.find(perfume => perfume.id === id));
            } else {
                // Return an empty array if no results are found
                return [];
            }
        }
}}