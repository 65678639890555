<template>

  <v-app> 
  
    <v-container>
        <div>
          <v-app-bar v-if="!isMobile" app color="white" elevation="0" class="app-bar-header">
            <div class="header-background"> <!-- Added this div -->
            <v-container class="header-container">
              <h1 class="header-title" style="cursor: pointer;">
                <span class="underline">Perfume</span> Prophet.
              </h1>
  
              <v-container fluid>
                <v-row>
                  <v-col cols="11">
                    <v-card flat>
                      <v-card-text class="search-bar-container">                      
                        <v-text-field
                          v-model="preQuery"
                          type="search"
                          label=""
                          clearable
                          solo
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          class="custom-search-field"
                          @click:prepend-inner="focusSearch"
                          ref="searchInput"
                          @keydown.enter="triggerSearch"
                          enterkeyhint="search"
                          placeholder="Search brand, name or notes"
                        ></v-text-field>                    
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="toggleDrawer" class="filter-button">
                      <v-icon dark>mdi-tune</v-icon>
                      <v-badge
                        v-if="activeFilterCount > 0"
                        :content="activeFilterCount"
                        color="#262626"
                        offset-y="32"
                        offset-x="-2"
                        bordered
                        style="border-width: 0.5px !important; border-color: white !important; border-style: solid !important;"
                      ></v-badge>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              
            </v-container>
          </div>
          
        </v-app-bar>
        
                  

          <div v-else>
            <h1 class="header-title-mobile"  style="cursor: pointer;">
                <span class="underline">Perfume</span> Prophet.
              </h1>
  
  
            <v-container fluid>
              <v-row>
                <v-col cols="11">
                  <v-card flat>
                    <v-card-text class="search-bar-container-mobile">
                    
                      <v-text-field
                        v-model="preQuery"
                        type="search"
                        label=""
                        clearable
                        solo
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        class="custom-search-field"
                        @click:prepend-inner="focusSearch"
                        ref="searchInput"
                        enterkeyhint="search"
                        @keydown.enter="triggerSearch"
                        placeholder="Search brand, name or notes"
                      ></v-text-field>
                    
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="1">
                  <v-btn icon @click="toggleDrawer" class="filter-button-mobile">
                    <v-icon dark>mdi-tune</v-icon>
                    <v-badge
                      v-if="activeFilterCount > 0"
                      :content="activeFilterCount"
                      color="#262626"
                      offset-y="32"
                      offset-x="-2"
                      bordered
                      style="border-width: 0.5px !important; border-color: white !important; border-style: solid !important;"
                    ></v-badge>
                  </v-btn>
                </v-col>
              </v-row>
               
              
                <div class="number-of-results-mobile" v-if="filteredPerfumes.length > 0 && filteredPerfumes.length <= 1000">                
                  {{ filteredPerfumes.length }} {{ filteredPerfumes.length === 1 ? 'perfume' : 'perfumes' }}                
                </div>
              
            
            </v-container>
          </div>



        </div>
      </v-container>
  
    
  <!-- APP DESIGN: Mobile & Flex -->
  
  <body>
  <template>
  
    <div v-if="!isMobile" class="placeholder"></div>

    <div class="number-of-results" v-if="filteredPerfumes.length > 0 && filteredPerfumes.length <= 1000 && !isMobile">                
                {{ filteredPerfumes.length }} {{ filteredPerfumes.length === 1 ? 'perfume' : 'perfumes' }}                
      </div>

        <div>
          <div class="perfume-container">
          <div :class="isMobile ? 'mobile-perfume-item' : 'desktop-perfume-item'" 
           v-for="(perfume, index) in paginatedItems" 
           :key="`perfume-${index}`">
      
      
  <!-- Compact View -->
  
  
    <div v-if="!showDetails" class="compact-view-container">
    <!-- Image Container -->
    <div class="compact-image-container">
      <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="compact-perfume-image">
    </div>
    <!-- Perfume Info and Scent Types -->
    <div class="compact-content">
      <!-- Perfume Name, Brand, and Rating -->
      <div class="mobile-perfume-info">
        <div v-if="isMobile">
          <span class="flex-perfume-name" :style="{ fontSize: perfume.name.length > 40 ? '0.93em' : (perfume.name.length > 30 ? '0.96em' : '1.12em') }">{{ perfume.name }}</span>
          <br>
          <span class="mobile-perfume-brand" :style="{ fontSize: perfume.name.length > 40 ? '0.93em' : (perfume.name.length > 30 ? '0.96em' : '1.12em') }">{{ perfume.brand }}</span>
        </div>
        <div v-if="!isMobile" class="flex-perfume-info">
          <span class="flex-perfume-name" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.name }}</span>
          <br>
          <span class="mobile-perfume-brand" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.brand }}</span>
        </div>
      </div>
  
      <!-- Scent Types as Chips -->
      <div v-if="isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          small
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
  
          <!-- Scent Types as Chips -->
          <div v-if="!isMobile" class="mobile-scent-types-compact">
        <v-chip 
          v-for="(type, index) in perfume.combined_types.split('-')"
          :key="`type-${perfume.id}-${index}`"
          :color="getScentTypeColor(type)"
          
          class="white--text text-uppercase mobile-chip-custom-compact">
          {{ type.toUpperCase() }}
        </v-chip>
      </div>
  
  
  
    </div>
  </div>
  
  
      <!-- Normal App View (non-compact)-->
  
      <div v-if="showDetails">
  
      <!-- Perfume Name, Brand, and Rating -->
      <div class="mobile-perfume-info">
  
      <div v-if="isMobile">
        <span class="mobile-perfume-name">{{ perfume.name }}</span>
        <span class="divider">|</span>
        <span class="mobile-perfume-brand">{{ perfume.brand }}</span>
      </div>
  
  
      <div v-if="!isMobile" class ="flex-perfume-info">
        <span class="flex-perfume-name" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.name }}</span>
        <br>
        <span class="mobile-perfume-brand" :style="{ fontSize: perfume.name.length > 40 ? '0.96em' : (perfume.name.length > 30 ? '1.00em' : '1.15em') }">{{ perfume.brand }}</span>
  
      </div>
    </div>
  
  
            
        <!-- Scent Types as Chips -->
        <div class="mobile-scent-types">
          <v-chip 
            v-for="(type, index) in perfume.combined_types.split('-')"
            :key="`type-${perfume.id}-${index}`"
            :color="getScentTypeColor(type)"
            class="white--text text-uppercase mobile-chip-custom"
            >
            {{ type.toUpperCase() }}
          </v-chip>
        </div>
        
      <div class="mobile-ratings">
            


<!-- Image -->
<div class="image-card" :style="{ backgroundColor: '#FFFFFF' }">
  <div class="image-container" style="position: relative;">
    <img :src="allImages[perfume.image_name] || require('@/assets/images/00_perfume_icon.webp')" alt="Perfume" class="mobile-perfume-image">
    
    <!-- Heart Icon for Adding to Favorites -->
    <v-btn icon @click="toggleFavorite(perfume.id)" style="position: absolute; top: 202px; left: -17%;">

      <v-icon :color="isFavorite(perfume.id) ? 'red' : '#efefef'">
          {{ isFavorite(perfume.id) ? 'mdi-heart' : 'mdi-heart-outline' }}
        </v-icon>

    </v-btn>
  </div>
</div>

    <v-row>
  
    <!-- Dynamic Text Box -->
  <v-col cols="12" class="py-1 ma-0">
    <div :class="isMobile ? 'mobile-text-box' : 'flex-text-box'"
            :style="{ fontSize: isMobile ? '' : `${perfume.name} ${perfume.brand}`.length > 55 ? '16px' : '16px' }">
        <span>
      {{ perfume.name }} is 
      {{ perfume.article ? perfume.article : 'a' }} 
      {{ perfume.concentration ? perfume.concentration : 'perfume' }} 
      by {{ perfume.brand}}{{ perfume.year && !isNaN(parseInt(perfume.year, 10)) && parseInt(perfume.year, 10) > 0 ? ' that was released in ' + parseInt(perfume.year, 10) : '' }}.
      </span>
    </div>
    </v-col>
  
  
  <!-- Mobile version -->
  <div v-if="isMobile" :key="chipsKey" class="scrollable-chips-container">
    <div class="chips-row">
      <v-chip
        v-for="(note, index) in perfume.notes_strong.split(',').filter(n => n.trim() !== '')"
        :key="`strong-note-${index}`"
        class="mobile-chip-strong-notes"      
      >
        {{ note.trim() }}
      </v-chip>
      <v-chip
        v-for="(note, index) in perfume.notes_medium.split(',').filter(n => n.trim() !== '')"
        :key="`medium-note-${index}`"
        class="mobile-chip-medium-notes"      
      >
        {{ note.trim() }}
      </v-chip>
      <v-chip
        v-for="(note, index) in perfume.notes_weak_adj.split(',').filter(n => n.trim() !== '')"
        :key="`weak-note-${index}`"
        class="mobile-chip-weak-notes"      
      >
        {{ note.trim() }}
      </v-chip>
    </div>
  </div>
  
  
  
  <div v-else class="desktop-chips-container" :key="chipsKey" @mousedown="mouseDown"
            @mousemove="mouseMove"
            @mouseup="mouseUp"
            @mouseleave="mouseLeave"
            @touchstart="touchStart"
            @touchmove="touchMove"
            @touchend="touchEnd">
            <div class="chips-row-desktop">
      <v-chip
        v-for="(note, index) in perfume.notes_smw_merged.replace(/,/g, '').length <= 50 ? perfume.notes_smw_merged.split(',') : perfume.notes_smw_merged.split(',').slice(0, Math.ceil(perfume.notes_smw_merged.split(',').length / 2))"
        :key="`first-row-note-${index}`"
        class="mobile-chip-custom-notes"
        @dblclick="handleChipDoubleClick(note.trim())"
      >
        <v-tooltip bottom open-delay="650">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ note.trim() }}</span>
          </template>
          <span>Double-click to search for perfumes with {{ note.trim() }} </span>
        </v-tooltip>
      </v-chip>
    </div>
    <!-- Render this row only if the total length of notes exceeds 40 -->
    <div class="chips-row-desktop" v-if="perfume.notes_smw_merged.replace(/,/g, '').length > 50">
      <v-chip
      v-for="(note, index) in perfume.notes_smw_merged.split(',').slice(Math.ceil(perfume.notes_smw_merged.split(',').length / 2))"
        :key="`second-row-note-${index}`"
        class="mobile-chip-custom-notes"
        @dblclick="handleChipDoubleClick(note.trim())"
      >
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ note.trim() }}</span>
          </template>
          <span>Double-click to search for perfumes with {{ note.trim() }} </span>
        </v-tooltip>
      </v-chip>
    </div>
  </div>
  
  
  
      <!-- Ratings Box for Longevity, Projection, and Price -->
  <!-- Ratings Box for Longevity, Projection, and Price -->
  <v-col cols="12" class="py-1 ma-0">
    <div class="mobile-rating-box">           
      <v-row>
        <v-col cols="12" class="py-1 ma-0">          
          <!-- Row 1: Projection -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Intensity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, sillageRating(perfume.sillage))"></div>
              </div>
            </v-col>
          </v-row>               
          
          <!-- Row 2: Longevity -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Longevity</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, longevityRating(perfume.longevity))"></div>
              </div>
            </v-col>
          </v-row>
  
          <!-- Row 3: Price -->
          <v-row class="rating-row">
            <v-col cols="4" class="rating-label">Price</v-col>
            <v-col cols="8" class="rating-bar-container">
              <div :style="generateBarContainerStyle()">
                <div class="rating-bar" :style="ratingBarStyle(perfume, priceToNumber(perfume.price))"></div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-col>
  
  
      </v-row>
  
      </div>
      </div>
  
          </div>
          </div>
          
          
         
          <v-btn
        fab
        fixed
        right
        :style="{ bottom: buttonBottom, backgroundColor: 'transparent', boxShadow: 'none' }"
        @click="buttonAction"
        class="transparent-btn"
        >
          <v-icon>{{ scrollY > 450 ? 'mdi-arrow-up' : (showDetails ? 'mdi-view-list' : 'mdi-view-grid') }}</v-icon>
        </v-btn>
  
  
          <div v-if="filteredPerfumes.length > itemsPerPage"> 
            <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="5"
            class="custom-pagination"
          ></v-pagination>
          <br>
          <br>    
          </div>
  
            
          <div v-else-if="filteredPerfumes.length === 0" class="no-results-container">
    <div class="no-results-message">
      Sorry, the prophet couldn't find a perfume for you.
    </div>
  
    <!-- Remove Gender Filter Button -->
    <v-btn v-if="selectedScentgender.length > 0"
           @click="removeFilter('gender')"
           color="primary"
           text>Remove Gender Filter</v-btn>
  
    <!-- Remove Intensity Filter Button -->
    <v-btn v-if="selectedSillage.length && selectedSillage.toString() !== [1, 4].toString()"
           @click="removeFilter('sillage')"
           color="primary"
           text>Remove Intensity Filter</v-btn>
  
    <!-- Remove Longevity Filter Button -->
    <v-btn v-if="selectedLongevity.length && selectedLongevity.toString() !== [1, 4].toString()"
           @click="removeFilter('longevity')"
           color="primary"
           text>Remove Longevity Filter</v-btn>
  
    <!-- Remove Price Filter Button -->
    <v-btn v-if="selectedPrice.length && selectedPrice.toString() !== [1, 5].toString()"
           @click="removeFilter('price')"
           color="primary"
           text>Remove Price Filter</v-btn>
  
    <!-- Remove Gender Filter Button -->
      <v-btn v-if="selectedBrand.length > 0"
           @click="removeFilter('brand')"
           color="primary"
           text>Remove Brand Filter</v-btn>
  
  
  </div>
  
  
  </div>
      <br>
      <br>
  
    </template>
  
  </body>





  <!--FILTER SECTION-->


<div :class="{'filter-drawer-open': isDrawerOpen, 'flex-filter-drawer': !isMobile, 'filter-drawer': isMobile}" class="filter-section">
 

<!-- Sticky Header -->
<div class="sticky-header" @click="toggleDrawer">
  <div class="close-btn-container">
    <v-btn icon >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
  <!-- Search Query Display -->
  <div v-if="searchQuery" class="search-query-display">
  <v-icon>mdi-magnify</v-icon> <!-- Use the mdi-magnify icon here -->
  <span class="search-term">{{ searchQuery }}</span>
</div>
</div>


  <!-- Scrollable Content Container -->
  <div class="filter-drawer-content" ref="drawerContent">        

    <!-- ScentGender Filter Dropdown --> 
    <div>
      <h4 style="margin-bottom: 5px">Gender</h4>
      <div class="filter-options">
        <v-btn
          v-for="option in scentgn"
          :key="option"
          :class="[
            isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
            tempSelectedScentgender.includes(option) ? (isMobile ? 'mobile-filter-option-button-selected' : 'filter-option-button-selected') : ''
          ]"
          @click="toggleScentgender(option)"
          outlined
          :text="!tempSelectedScentgender.includes(option)"
          tile
        >
          {{ option }}
        </v-btn>
      </div>
    </div>

    <!-- Scenttype Filter Buttons --> 
    <div>
    <h4 style="margin-bottom: 5px">Scent Profile</h4>
    <div class="filter-options">
      <!-- Iterate over the scent types and create buttons for each -->
      <template v-for="option in type">
        <v-btn
          :key="option"
          :class="[
            isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
            isMobile && tempSelectedType[option] === 'selected' ? 'mobile-filter-option-button-selected' : '',
            !isMobile && tempSelectedType[option] === 'selected' ? 'filter-option-button-selected' : '',
            isMobile &&tempSelectedType[option] === 'excluded' ? 'mobile-filter-option-button-excluded' : '',
            !isMobile &&tempSelectedType[option] === 'excluded' ? 'filter-option-button-excluded' : ''
          ]"
          @click="toggleType(option)"
          outlined
          :text="!tempSelectedType[option]"
          tile
          style="margin-bottom: 8px;"
        >
          <span :style="tempSelectedType[option] === 'excluded' ? 'text-decoration-line: line-through; text-decoration-thickness: 2px;' : ''">
            {{ option }}
          </span>
        </v-btn>
      </template> <br><span style="font-size: 13px; color: grey; margin-left: 1px">    Doubletab to exclude</span>
    </div> 
    </div>


<!-- Projection Filter with Range Slider -->
<div>
    <h4 style="margin-bottom: 12px">Intensity</h4>
    <div class="filter-options-slider">
        <VueSlider
            v-model="tempSelectedSillage"
            :min="1"
            :max="4"
            :lazy="true"
            :tooltip="'none'"
            :processStyle="{ backgroundColor: 'black' }"
            :railStyle="{ backgroundColor: '#cccccc' }"            
            :marks="sillageMarks"
        />
    </div>
</div>

<!-- Longevity Filter with Range Slider -->
<div>
    <h4 style="margin-bottom: 12px">Longevity</h4>
    <div class="filter-options-slider">
        <VueSlider
            v-model="tempSelectedLongevity"
            :min="1"
            :max="4"
            :lazy="true"
            :tooltip="'none'"
            :processStyle="{ backgroundColor: 'black' }"
            :railStyle="{ backgroundColor: '#cccccc' }"  
            :marks="longevityMarks"
        />
    </div>
</div>

    <!-- Price Filter with Range Slider -->
  <div>
    <h4 style="margin-bottom: 12px">Price</h4>
    <div class="filter-options-slider">
      <VueSlider
      v-model="tempSelectedPrice"
    :min="1"
    :max="5"
    :lazy="true"
    :tooltip="'none'"
    :processStyle="{ backgroundColor: 'black' }"
    :railStyle="{ backgroundColor: '#cccccc' }"
    :marks="priceMarks"
      />
    </div>
  </div>




    
<!-- Brand Filter Dropdown -->


      <div style="position: relative; margin-bottom: 33px">
        <h4 style="margin-bottom: 5px; cursor: pointer" @click="toggleBrandsVisibility">
          Brand
          <span :class="{'triangle-down': !showBrands, 'triangle-right': showBrands}"></span>
        </h4>
        <v-badge
          v-if="BrandactiveFilterCount > 0"
          :content="BrandactiveFilterCount"
          color="#262626"
          offset-y="-24"
          offset-x="-60"
          bordered
          style="margin-left: 8px; position: absolute; border-width: 0.5px !important; border-color: white !important; border-style: solid !important;"
   
        ></v-badge>
        
        <div class="filter-options" v-show="showBrands">

  <v-btn
    v-for="brand in brands"
        :key="brand"
        :class="[
          isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
          tempSelectedBrand.includes(brand) ? (isMobile ? 'mobile-filter-option-button-selected' : 'filter-option-button-selected') : ''
        ]"
        @click="toggleBrand(brand)"
        outlined
        :text="!tempSelectedBrand.includes(brand)"
        tile
        style="margin-bottom: 8px;"
  >
    {{ brand }}
  </v-btn>

</div>

    </div>



    <!-- Sorting Buttons -->
<div>
  <h4 style="margin-bottom: 5px">Sort by</h4>
  <div class="filter-options">
    <v-btn
      v-for="option in ['name', 'brand', 'price']"
      :key="option"
      :class="[
        isMobile ? 'mobile-filter-option-button' : 'filter-option-button', 
        isMobile && tempSort === option ? 'mobile-filter-option-button-selected' : '',
        !isMobile && tempSort === option ? 'filter-option-button-selected' : ''
      ]"
      @click="selectTempSort(option)"
      outlined
      :text="tempSort !== option"
      tile
    >
      {{ option.charAt(0).toUpperCase() + option.slice(1) }}
    </v-btn>
  </div>
</div>



    </div>

  
<!-- Fixed Footer with buttons -->
<div class="filter-drawer-footer">
  <v-btn  @click="DeleteTempFilters" class="left-button">  
    Delete all 
  </v-btn>
  
  <v-btn dark fab color="black" @click="applyFilters" class="right-button">
    <span>
  <span v-if="previewFilteredPerfumes.length === 0">no perfumes found</span>
  <span v-else>
    Show 
    <span v-if="previewFilteredPerfumes.length > 1000">1000+</span>
    <span v-else>{{ previewFilteredPerfumes.length }}</span>
    {{ previewFilteredPerfumes.length === 1 ? 'perfume' : 'perfumes' }}
  </span>
</span>




  </v-btn>
</div>


</div>




<!--GENERAL-->
<div :class="{'overlay': true, 'visible': isDrawerOpen}" @click="toggleDrawer"></div>

  </v-app>      
</template>


<script>
import axios from 'axios';
import VueSlider from 'vue-slider-component';
import { deleteAllFilters, deleteTempFilters, resetTempFilters, removeFilter } from '@/utils/FilterReset.js';
import { sortPerfumes, shuffleArray } from '../utils/sort.js';
import { getScentTypeColor } from '@/utils/colorMapping.js';
import { filterPerfumes, previewFilteredPerfumes} from '../utils/Filters.js';


export default {
  name: 'PerfumeDataTable',
  data() {
    return {  
      
      totalVisibleRows: 20, // Initial number of items to display
      incrementAmount: 20, // Number of items to add on each scroll to the bottom        
      perfumes: [],
      filteredPerfumes: [],
      brands: [],      
      type: [],
      scentgn: [], 
      search: '',
      preQuery: '', // new property for preQuery
      searchQuery: '', // for searchbar
      
      selectedBrand: [], 
      selectedScentgender: [],
      selectedType: {},
      selectedPrice: [1, 5],
      selectedSillage: [1, 4],
      selectedLongevity: [1, 4],
      tempSelectedScentgender: [], 
      tempSelectedType: {},
      tempSelectedBrand: [],
      
      timeout: null,  
      showDetails: true, // New property to control the visibility of detailed ratings
      allImages: {}, // for pictures
      currentPage: 1, // for pagination
      itemsPerPage: 12,
      isDrawerOpen: false, //for sliding filter window    
      scrollTop: 0,
      currentSort: '', // current sort parameter
      tempSort: '',
      showBrandOptions: false,
      showBrands: false,
      isMobile: false,
      lastTap: 0,
      lastTappedOption: null, 
      maxExcluded: 8,
      currentLetter: 'A', // Initial letter
      scrollPosition: 0,
      isMouseDown: false,
      startX: 0,
      scrollLeft: 0,
      scrollY: 0,
      lastScrollY: 0, 
      chipsKey: 0,
      
      favorites: JSON.parse(localStorage.getItem('favorites')) || [],

      buttonBottom: '85px', // Default bottom position for the button

      timeMachine: [
        { label: '2009', description: 'release date was 2009' },
        { label: 'before 2009', description: 'was released before 2009' },
        { label: 'after 2009', description: 'was released after 2009' },
        { label: '2009 - 2015', description: 'was released between 2009 and 2015' },
        { label: 'new', description: 'all perfumes that were released this year or last year' },
        { label: 'classic', description: 'on the market for 25 years or longer' },
        { label: 'legendary', description: 'on the market for 50 years or longer' },
      ],
      notes: ['Rose', 'Lavender', 'Lavender Rose'],
    

      tempSelectedPrice: [1, 5],
      priceCategories: ['very low','low', 'medium', 'high', 'very high'],
      priceMarks: 
        { 1: '$',
        2: '$$',
        3: '$$$',
        4: '$$$$',
        5: 'Luxury'},

      tempSelectedLongevity: [1, 4],  // Default range values for longevity
      longevityCategories: ['short', 'medium', 'long', 'very long'],
      longevityMarks: {            
            1: 'Short',
            2: 'Medium',
            3: 'Long',
            4: 'Very Long'
          },

      tempSelectedSillage: [1, 4],    // Default range values for sillage
      sillageCategories: ['low', 'medium', 'strong', 'very strong'],
      sillageMarks: {
            1: 'Silent',
            2: 'Medium',
            3: 'Loud',
            4: 'Very Loud'
          }


    };
  },

   components: {
    VueSlider
  },
  
  computed: {
            
    activeFilterCount() {
    let count = 0;

    // Check if the longevity slider is set to its full range
    if (this.selectedLongevity.length > 0 && !(this.selectedLongevity[0] === 1 && this.selectedLongevity[1] === this.longevityCategories.length)) {
      count++;
    }

    // Check if the sillage slider is set to its full range
    if (this.selectedSillage.length > 0 && !(this.selectedSillage[0] === 1 && this.selectedSillage[1] === this.sillageCategories.length)) {
      count++;
    }

    // Check if the price slider is set to its full range
    if (this.selectedPrice.length > 0 && !(this.selectedPrice[0] === 1 && this.selectedPrice[1] === this.priceCategories.length)) {
      count++;
    }

    // Count other selected filters
    count += this.selectedScentgender.length;
    count += this.selectedBrand.length;
    count += Object.keys(this.selectedType).length;

    return count;
  },

  BrandactiveFilterCount() {
    return this.tempSelectedBrand ? this.tempSelectedBrand.length : 0;
  },

  //for Pagination
  paginatedItems() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredPerfumes.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.filteredPerfumes.length / this.itemsPerPage);
  },

//Sorting for brands filter
sortedBrands() {
    // First, sort the brands alphabetically and then return.
    return this.brands.slice().sort((a, b) => a.localeCompare(b));
  },
  firstRowBrands() {
    // Return brands for the first row (1st, 3rd, 5th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 === 0);
  },
  secondRowBrands() {
    // Return brands for the second row (2nd, 4th, 6th, etc.)
    return this.sortedBrands.filter((_, index) => index % 2 !== 0);
  },

  
  previewFilteredPerfumes,
  

  },

  watch: {
  searchQuery(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.updateUrlWithFilters();
    }
  },
  currentPage(newValue, oldValue) {
    if (newValue !== oldValue) {
      window.scrollTo(0, 0); // Scrolls to the top of the page
      this.chipsKey += 1;
    }
  },
  $route(to) {
    this.syncFiltersWithRoute(to.query);
  },
},



  created() {    
    this.allImages = this.importAllImages(); //Images
    this.fetchPerfumes().then(() => {
    this.sortPerfumes(); // Initial random sorting
    this.loadFiltersFromUrl(); // Load filters from URL if present

   

  });
  },  
     
  mounted() {
        
   
  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      window.scrollTo(0, 0);
      observer.disconnect(); // Stop observing after scrolling
    }
  });
  observer.observe(this.$el);    
     
    this.onResize(); // Set initial state based on current window size
    window.addEventListener('resize', this.onResize); // Add resize listener when component mounts
    window.addEventListener('scroll', this.handleScroll);
    this.syncFiltersWithRoute(this.$route.query); // Process URL parameters on component load
    this.filterPerfumes(); // Apply the filters based on the URL
    this.sortPerfumes(); // Sort the perfumes based on the filters
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize); // Remove listener when component is about to be destroyed
    window.removeEventListener('scroll', this.handleScroll);
  },

  beforeRouteEnter(to, from, next) {
  next((vm) => {
    vm.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  });
},

  
  methods: {
  

    async fetchPerfumes() {
  try {
    // Check if there's already a shuffled dataset in local storage
    const storedPerfumes = localStorage.getItem('shuffledPerfumes');
    
    if (storedPerfumes) {
      // If it exists, use it
      this.perfumes = JSON.parse(storedPerfumes);
    } else {
      // Otherwise, fetch the data and shuffle it
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/perfumes`);
      this.perfumes = response.data.map((perfume, index) => ({
        ...perfume,
        id: `perfume-${index}` // Ensure unique ID for each perfume
      }));
      
      // Shuffle the dataset
      this.perfumes = this.shuffleArray(this.perfumes);
      
      // Store the shuffled dataset in local storage
      localStorage.setItem('shuffledPerfumes', JSON.stringify(this.perfumes));
    }
    
    this.filteredPerfumes = this.perfumes.slice(0, this.totalVisibleRows);
    this.brands = [...new Set(this.perfumes.map(perfume => perfume.brand))].sort();
    this.scentgn = [...new Set(this.perfumes.map(perfume => perfume.scentgn))].sort();
    this.longevity = ['short', 'medium', 'long', 'very long'];
    this.sillage = ['low', 'medium', 'strong', 'very strong'];
    this.price = ['low', 'medium', 'high', 'very high'];
    this.type = ['Fresh', 'Flowery', 'Sweet', 'Fruity', 'Citric', 'Woody', 'Creamy', 'Aromatic', 'Powdery', 'Green', 'Aquatic', 
                'Synthetic', 'Gourmand', 'Leathery', 'Mineral', 'Oriental', 'Smoky', 'Animalic'],
    // Apply any initial filters
    this.filterPerfumes();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  },

  onResize() {
  this.isMobile = window.innerWidth < 750; // Define mobile Device
  this.$forceUpdate();
  },

  // Show hide brands
  toggleBrandsVisibility() {
    this.showBrands = !this.showBrands;
  },

  scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  },

  focusSearch() {
    this.$refs.searchInput.focus(); // Focus the input field when tab on magnify
  },
  closeKeyboard() {
    // Blur the input field to close the keyboard on mobile devices
    if (this.$refs.searchInput) {
      this.$refs.searchInput.blur();
    }
  },

  handleScroll() {
  const currentScrollY = window.scrollY;

  // Handle toolbar visibility
if (this.isMobile) {
  if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
    // Scrolling down and past 600px
    this.buttonBottom = '35px'; // Set the button to 45px when scrolled more than 600px
  } else if (currentScrollY < this.lastScrollY || currentScrollY <= 450) {
    // Scrolling up or at the top of the page
    this.buttonBottom = '85px'; // Reset the button to 85px when scrolled less than 600px
  }
}

  // Update the last scroll position
  this.lastScrollY = currentScrollY;
  this.scrollY = currentScrollY;
},



    buttonAction() {
    if (this.scrollY > 450) {
      this.scrollToTop();
    } else {
      this.showDetails = !this.showDetails;
    }
  },

  toggleDetails() {
    this.showDetails = !this.showDetails;
  },

    mouseDown(e) {
      this.isMouseDown = true;
      this.startX = e.pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
      e.currentTarget.style.cursor = 'grabbing'; // Change cursor to grabbing
      document.body.style.userSelect = 'none'; // Prevent text selection
    },
    mouseMove(e) {
      if (!this.isMouseDown) return;
      e.preventDefault();
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 2; // scroll-fast
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
    mouseUp(e) {
      this.isMouseDown = false;
      e.currentTarget.style.cursor = 'grab'; // Change cursor back to grab
      document.body.style.userSelect = ''; // Re-enable text selection
    },
    mouseLeave(e) {
      this.isMouseDown = false;
      e.currentTarget.style.cursor = 'grab'; // Change cursor back to grab
      document.body.style.userSelect = ''; // Re-enable text selection
    },
    touchStart(e) {
      this.isMouseDown = true;
      this.startX = e.touches[0].pageX - e.currentTarget.offsetLeft;
      this.scrollLeft = e.currentTarget.scrollLeft;
    },
    touchMove(e) {
      if (!this.isMouseDown) return;
      const x = e.touches[0].pageX - e.currentTarget.offsetLeft;
      const walk = (x - this.startX) * 2; // scroll-fast
      e.currentTarget.scrollLeft = this.scrollLeft - walk;
    },
    touchEnd() {
      this.isMouseDown = false;
    },

    noteDescription(note) {
  if (note.includes(' ')) {
    // Split the note into words and join them with " AND "
    const words = note.split(' ');
    return `all perfumes that contain ${words.join(' and ')}`;
  } else {
    // For single-word notes, return the default description
    return `all perfumes that contain ${note}`;
  }
},

sendEmail() {
    const email = 'mail@perfumeprophet.com';
    window.location.href = `mailto:${email}`;
  },

  addToSearchQuery(term) {
    if (this.preQuery) {
      this.preQuery += ` ${term}`;
    } else {
      this.preQuery = term;
    }

    if (this.searchQuery) {
      this.searchQuery += ` ${term}`;
    } else {
      this.searchQuery = term;
    }

    this.filterPerfumes(); // Refilter perfumes with the updated search query
  },
  // New method to handle double-click event
  handleChipDoubleClick(term) {
    this.addToSearchQuery(term);
  },

  triggerSearch() {
    this.searchQuery = this.preQuery; // Set the actual search query

    // Reset all filter-related states if necessary
    this.selectedBrand = [];
    this.selectedSillage = [1, 4];
    this.selectedScentgender = [];
    this.selectedLongevity = [1, 4];
    this.selectedPrice = [1, 5];
    this.selectedType = {}; 
    this.tempSelectedBrand = []; 
    this.tempSelectedSillage = [1, 4]; 
    this.tempSelectedScentgender = [];
    this.tempSelectedLongevity = [1, 4]; 
    this.tempSelectedPrice = [1, 5]; 
    this.tempSelectedType = {};
    this.tempSort = '';
    this.currentSort = '';

    // Update the URL with the new search query
    this.updateUrlWithFilters();
    
    this.closeKeyboard();
    // Call the method to filter perfumes again
    this.filterPerfumes();
    this.sortPerfumes();
  },
  
//Get all pictures in
  importAllImages() {
    const context = require.context('@/assets/images/', false, /\.webp$/);
    return context.keys().reduce((images, path) => {
      const key = path.split('./')[1].split('.webp')[0]; // Extract the name without the extension
      images[key] = context(path);
      return images;
    }, {});
  },

  updateUrlWithFilters() {
    const query = {};

    if (this.searchQuery) {
      query.search = this.searchQuery;
    }

    if (this.selectedScentgender.length) {
      query.gender = this.selectedScentgender.join(',');
    }
    
    // Handle selected and excluded types
    const selectedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'selected');
    const excludedTypes = Object.keys(this.selectedType).filter(key => this.selectedType[key] === 'excluded');

    if (selectedTypes.length) {
      query.type = selectedTypes.join(',');
    }
    
    if (excludedTypes.length) {
      query.excludeType = excludedTypes.join(',');
    }

    if (this.selectedBrand.length) {
      query.brand = this.selectedBrand.join(',');
    }
    
    // Add slider values to the URL query
    if (this.selectedPrice[0] !== 1 || this.selectedPrice[1] !== 5) {
      query.price = this.selectedPrice.join(',');
    }
    
    if (this.selectedLongevity[0] !== 1 || this.selectedLongevity[1] !== 4) {
      query.longevity = this.selectedLongevity.join(',');
    }
    
    if (this.selectedSillage[0] !== 1 || this.selectedSillage[1] !== 4) {
      query.intensity = this.selectedSillage.join(',');
    }

    if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
      this.$router.push({ query });
    }
    
  },


  loadFiltersFromUrl() {
    const query = this.$route.query;

    if (query.search) {
      this.searchQuery = query.search;
      this.preQuery = query.search; // If you want to reflect it in the preQuery as well
    }

    if (query.gender) {
      this.selectedScentgender = query.gender.split(',');
    }
    
    if (query.type) {
      this.selectedType = query.type.split(',').reduce((acc, type) => {
        acc[type] = 'selected';
        return acc;
      }, {});
    }

    if (query.excludeType) {
      query.excludeType.split(',').forEach(type => {
        this.$set(this.selectedType, type, 'excluded');
      });
    }

    if (query.brand) {
      this.selectedBrand = query.brand.split(',');
    }

    // Load slider values from the URL
    if (query.price) {
      this.selectedPrice = query.price.split(',').map(Number);
    }

    if (query.longevity) {
      this.selectedLongevity = query.longevity.split(',').map(Number);
    }

    if (query.intensity) {
      this.selectedSillage = query.intensity.split(',').map(Number);
    }

    // Add more filters as needed
  },

 syncFiltersWithRoute(query) {
    // Process the search query first
    if (query.search) {
      this.searchQuery = query.search;
      this.preQuery = query.search;
    } else {
      this.searchQuery = '';
      this.preQuery = '';
    }

  // Process type filters (including exclusions)
  this.selectedType = {};
    if (query.type) {
      query.type.split(',').forEach(type => {
        this.$set(this.selectedType, type, 'selected');
      });
    }

    if (query.excludeType) {
      query.excludeType.split(',').forEach(type => {
        this.$set(this.selectedType, type, 'excluded');
      });
    }

    // Now process the other filters
    if (query.gender) {
      this.selectedScentgender = query.gender.split(',');
    } else {
      this.selectedScentgender = [];
    }

    if (query.brand) {
      this.selectedBrand = query.brand.split(',');
    } else {
      this.selectedBrand = [];
    }

    // Update slider values
    if (query.price) {
      this.selectedPrice = query.price.split(',').map(Number);
    } else {
      this.selectedPrice = [1, 5];
    }

    if (query.longevity) {
      this.selectedLongevity = query.longevity.split(',').map(Number);
    } else {
      this.selectedLongevity = [1, 4];
    }

    if (query.intensity) {
      this.selectedSillage = query.intensity.split(',').map(Number);
    } else {
      this.selectedSillage = [1, 4];
    }

    // Apply the filters and search
    this.filterPerfumes();
    this.sortPerfumes();
  },

  

  ratingBarStyle(perfume, ratingValue) {
    const endColor = perfume.dom_color || 'black';
    const widthPercentage = (ratingValue / 5) * 100;
    const style = {
      background: `linear-gradient(to right, #eeeeee, ${endColor})`,
      width: `${widthPercentage}%`,
      height: '100%',
      borderRadius: '2px',
    };

    return style;
  },

  generateBarContainerStyle() {
    return {
      backgroundColor: 'white',
      width: '100%',
      height: '50%',
      position: 'relative',
      borderRadius: '10px',
      overflow: 'hidden', // Ensures the inner bar respects the border radius
    };
  },

    valueRating(perfume) {
      return perfume.someValue; // Replace with your logic
    },


    //Toggle Drawer filter area
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
      if (this.isDrawerOpen) {
        // Capture current scroll position
        this.scrollPosition = window.scrollY;
        document.body.style.top = `-${this.scrollPosition}px`;
        document.body.classList.add('no-scroll');
    
        // Scroll the filter drawer content to the top
        this.$nextTick(() => {
          if (this.$refs.drawerContent) {
            this.$refs.drawerContent.scrollTop = 0;
          }
        });
        this.resetTempFilters();
      } else {
        // Reset temporary filters to actual filters
        document.body.classList.remove('no-scroll');
        document.body.style.top = '';
        window.scrollTo(0, this.scrollPosition); // Restore scroll position
        this.showBrands = false; // Close brands area
      }
    },



 // Removes certain filters when pressing 'Remove Filters' buttons when no results
    removeFilter(filterType) {
      removeFilter(filterType, this);
    },

  toggleBodyScroll(isOpen) {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  },

  toggleFavorite(perfumeId) {
      if (this.isFavorite(perfumeId)) {
        this.favorites = this.favorites.filter(id => id !== perfumeId);
      } else {
        this.favorites.push(perfumeId);
      }
      localStorage.setItem('favorites', JSON.stringify(this.favorites));
    },
    isFavorite(perfumeId) {
      return this.favorites.includes(perfumeId);
    },


  //Filter Reset Button & Sort when exit the filtermenu without apply
  resetTempFilters() {
    resetTempFilters(this);
  },

//Filter Reset Button & Sort (for Delete all Button in Filtermenu)
  DeleteTempFilters() {
    deleteTempFilters(this);
  },

  //Click Logo to start new search
  DeleteAllFilters() {
      deleteAllFilters(this);
      this.filterPerfumes();
    },

// Sorting
sortPerfumes() {
      this.filteredPerfumes = sortPerfumes(
        this.filteredPerfumes, 
        this.currentSort, 
        this.priceToNumber, 
        this.searchQuery
      );
    },
    
    shuffleArray(array) {
      return shuffleArray(array);
    },

  toggleScentgender(option) {
    const index = this.tempSelectedScentgender.indexOf(option);
    if (index > -1) {
      this.tempSelectedScentgender.splice(index, 1);
    } else {
      this.tempSelectedScentgender.push(option);
    }
  },

  toggleType(option) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - this.lastTap;
      const doubleTapThreshold = 210; // Adjusted threshold for double-tap detection

      // Count currently excluded items
      const excludedCount = Object.values(this.tempSelectedType).filter(state => state === 'excluded').length;

      if (this.lastTappedOption === option && tapLength < doubleTapThreshold && tapLength > 0) {
        // Double-tap detected on the same item
        if (this.tempSelectedType[option] === 'excluded') {
          this.$delete(this.tempSelectedType, option); // Bring it back to normal state
        } else {
          if (excludedCount < this.maxExcluded) {
            this.$set(this.tempSelectedType, option, 'excluded');
          } else {
            // Optional: Provide feedback to the user that they can't exclude more items
            
          }
        }
        this.lastTappedOption = null; // Reset last tapped option
      } else {
        // Single tap detected or different item tapped
        const currentState = this.tempSelectedType[option];
        const selectedCount = Object.keys(this.tempSelectedType).filter(key => this.tempSelectedType[key] === 'selected').length;

        if (currentState === 'selected') {
          this.$delete(this.tempSelectedType, option);
        } else if (currentState === 'excluded') {
          this.$delete(this.tempSelectedType, option);
        } else {
          // Only add a new selected if fewer than 15 are already selected
          if (selectedCount < 8) {
            this.$set(this.tempSelectedType, option, 'selected');
          }
        }
      }

      this.lastTap = currentTime; // Update last tap time
      this.lastTappedOption = option; // Update last tapped option
    },
  

toggleBrand(option) {
    const index = this.tempSelectedBrand.indexOf(option);
    if (index > -1) {
      this.tempSelectedBrand.splice(index, 1);
    } else {
      this.tempSelectedBrand.push(option);
    }
  },

  selectTempSort(option) {
    this.tempSort = this.tempSort === option ? '' : option; 
  },
  
  applyFilters() {
    this.selectedScentgender = [...this.tempSelectedScentgender];
    this.selectedLongevity = [...this.tempSelectedLongevity];
    this.selectedSillage= [...this.tempSelectedSillage];
    this.selectedType = { ...this.tempSelectedType };
    this.selectedPrice = [...this.tempSelectedPrice];
    this.selectedBrand = this.tempSelectedBrand;
    this.currentSort = this.tempSort;

    // Update the URL with the selected filters
    this.updateUrlWithFilters();

    this.sortPerfumes();
    this.filterPerfumes(); // Call the filter method 
    this.toggleDrawer(); // Close the drawer
  },
  

  

////APPLY FILTERS AND SEARCH /////
  filterPerfumes,


  // Reset pagination, scroll to top and reset horizontal chips slider
  resetPaginationAndScroll() {
        this.currentPage = 1;
        this.chipsKey += 1; // Update chipsKey to reset scroll position       
    },    

  // convert categories into numerics
  sillageRating(sillage) {
    const ratingValues = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'strong': 4,
      'very strong': 5
    };
    return ratingValues[sillage.toLowerCase()] || 0; // Default to 0 if not found
  },

  longevityRating(longevity) {
    const ratingValues = {
      'very short': 1,
      'short': 2,
      'medium': 3,
      'long': 4,
      'very long': 5
    };
    return ratingValues[longevity.toLowerCase()] || 0; // Default to 0 if not found
  }, 
 
  priceToNumber(price) {
    const priceMap = {
      'very low': 1,
      'low': 2,
      'medium': 3,
      'high': 4,
      'very high': 5
    };
    return priceMap[price.toLowerCase()] || 0;
  },

getScentTypeColor 
    
  },
};

</script>


<style>


/* GENERAL APP SETTINGS */
  
/* App is never broader than 1400 px*/
  .v-application {
    max-width: 1400px;
    margin: auto;
}

/* Grey out app when filters open */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Start with transparent */
  z-index: 800; /* Below the drawer but above other content */
  transition: background-color 0.25s ease-in-out;
  visibility: hidden; /* Start with hidden */
  opacity: 0; /* Start fully transparent */
}

.overlay.visible {
  visibility: visible;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6); /* End with semi-transparent black */
}
  
/*Prevent double tab zoom in*/
button {
  touch-action: manipulation !important;
}

/*Prevent grey marks*/
.v-btn::before {
  opacity: 0 !important;
}


/*Prevent overscroll refresh*/
body, html {
  overscroll-behavior-y: none !important;
}



 /*MAIN APPLICATION STYLE*/
    
  
  /*Header*/
  
  .header-title-mobile {    
    text-align: left;
    margin-top: 3px;
    margin-bottom: 6px;
    margin-left: 12px;
    font-size: 1.15em;
    color: #000000;     
  }

  .header-title {    
    text-align: left;
    margin-top: 5px;
    margin-bottom: 1px;
    margin-left: 45px;
    font-size: 1.15em;
    color: #000000;   
   
  }

  .app-bar-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white !important;
  height: 117px !important;
  padding: 0;
 
}

.header-wrapper {
  width: 100%;
}

.header-background { /* Added this class */
  background-color: white;
  width: 100%;
  margin-top: 42px;
  
}
  .header-container {
  max-width: 1400px;
  margin-top: 42px;
  margin: 0 auto; /* Center the content */
  background-color: white;
  height: 80px;
  padding: 0 !important;
  
}

.placeholder {
  height: 30px !important;
}


/*Searchbar*/

/* Search Bar Container */
.search-bar-container {
  max-width: 100%;
  padding: 0; /* Remove any default padding */
  box-shadow: none !important; /* Ensure no shadow for a cleaner look */
  max-height: 38px !important;
  background-color: white;
  margin-bottom: 12px;
  margin-top: 5px;
  margin-left: 30px;
}

.search-bar-container-mobile {
  max-width: 100%;
  padding: 0; /* Remove any default padding */
  box-shadow: none !important; /* Ensure no shadow for a cleaner look */
  max-height: 38px !important;
  background-color: white;
  margin-bottom: 15px;
}

/* Custom Search Field Styling */
.custom-search-field {
  background-color: white; /* White background for the input field */
  border: 1.7px solid #262626; /* Light grey border color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: none !important; /* Ensure no shadow for a cleaner look */
  min-height: 38px !important; /* Increase the height of the input field */
}

/* Icon adjustments */
.custom-search-field .v-icon {
  color: #5f6368; /* Default grey for all icons */
}

/* Adjustments for the clear icon */
.v-input__icon--clear .v-icon {
  color: #5f6368; /* Keep the clear icon grey */
  background-color: transparent; /* No background for the clear icon */
  margin-right:44px;
}

/* Style adjustments for the filter button integrated into the search bar */
.custom-search-field .v-input__append-inner {
  margin-right: 1px; /* Align correctly */  
}

/* Ensuring that the icon color does not change on focus or hover */
.custom-search-field .v-text-field--focused .v-input__icon--append .v-icon,
.custom-search-field:hover .v-input__icon--append .v-icon {
  color: white !important; /* Keeps the icon white even when the field is focused or hovered */
}

/* Adjust the position of the filter button */
.filter-button {
  margin-left: -4px; /* Adjust the margin to align the button to the right */
  margin-top: 6px;
  margin-bottom: 0px;
}

/* Style adjustments for the filter button */
.filter-button .v-btn__content {
  color: white; /* White icon color */
  background-color: #222222; /* Black background color */
  border-radius: 10px; /* Make it circular */
  min-width: 51px !important; /* Set width to create a circular button */
  height: 52px; /* Set height to create a circular button */
  padding: 3px; /* Padding to create some space around the icon */
  
}

/* Ensure the icon color remains white */
.filter-button .v-icon {
  color: white;
}

/* Adjust the position of the filter button */
.filter-button-mobile {
  margin-left: -34px; /* Adjust the margin to align the button to the right */
  margin-top: 7.5px;
  margin-bottom: 15px;
}

/* Adjust the position of the filter button */
.filter-button-mobile {
  margin-left: -34px; /* Adjust the margin to align the button to the right */
  margin-top: 7.5px;
  margin-bottom: 15px;
}

/* Style adjustments for the filter button */
.filter-button-mobile .v-btn__content {
  color: white; /* White icon color */
  background-color: #222222; /* Black background color */
  border-radius: 10px; /* Make it circular */
  min-width: 51px !important; /* Set width to create a circular button */
  height: 52px; /* Set height to create a circular button */
  padding: 3px; /* Padding to create some space around the icon */
}

/* Ensure the icon color remains white */
.filter-button-mobile .v-icon {
  color: white;
}

/* Text within Search bar */
.custom-search-field .v-text-field__slot input {
  height: 100% !important; /* Take full height of its container */
  font-size: 17px !important; /* Increase the font size for better visibility */ 
}

.number-of-results {
  margin-left: 45px; /* Match this with the margin-left of header-title to align */
  font-size: 14px; /* Slightly larger font size for visibility */
  color: grey;
  min-height: 17px;
  display: flex;
  align-items: center;
  padding-top: 2px; /* Adjust padding to fine-tune the vertical alignment */
}

/* Number of Results Styling for Mobile */
.number-of-results-mobile {
  font-size: 14px; /* Font size for mobile */
  color: grey; 
}


/* Tiles Main Container */

.perfume-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .mobile-perfume-item {
    flex-direction: column;
    margin-bottom: 15px; /* Tightens the space between items */
    padding: 25px;
    padding-top: 8px; 
    padding-bottom: 19px; 
    border-bottom: 1px solid #c7c7c7; /* Very fine line */
    display: block;
    width: 100%;
    max-width: 480px; 
  }
    
  .desktop-perfume-item {
    flex: 0 1 30%; /* Adjusts to about three items per row */
    margin-top: 20px;
    margin-bottom: 25px;
    margin-left: 8px;
    margin-right: 8px;
    min-width: 350px;
    max-width: 370px;
  }


/* Header: Name and Brand */

.mobile-perfume-name, .mobile-perfume-brand {
    display: inline-block;
    vertical-align: middle;
  }
  
  .mobile-perfume-name {
    font-weight: bold;
    font-size: 1.2em  
  }

  .flex-perfume-name {
    font-weight: bold;    
  }
  
  .divider {
    margin: 0 5px;
    color: grey;
    font-size: 1.2em; 
  }
  
  .mobile-perfume-brand {
    color: grey;
    font-weight: normal;
    font-size: 1.2em; 
  }
  

/* Image */

.image-card {
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px; /* Add space above */
    margin-bottom: 10px; /* Add space below */
    min-height: 130px;
    border-radius: 10px; /* Rounded edges */
  }

  .image-container {
    max-width: 100%;
    display: flex;
    justify-content: center; 
    overflow: visible;
    border-radius: 10px
  }
  
  .mobile-perfume-image {
    height: 240px;
    transform: translateY(-3%); /* Adjust position for small screens */
    border-radius: 10px
  }  
  

/* Infotext Box */

  .mobile-perfume-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Adjusts the space to the next section */
  }

  .flex-perfume-info {
    height: 58px;
  }


  /* Scenttypes */

  .mobile-scent-types {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
  }
  
  .mobile-chip-custom {
    font-size: 1em; 
    color: white; 
    min-width: 81px !important;
    max-height: 30px !important;    
    text-align: center; 
    justify-content: center;
    border-radius: 10px !important;
  }


/* Compact view */

  
.compact-view-container {
  display: flex;
  align-items: center;
}

.compact-image-container {
  flex: 0 0 50px; /* Adjust the size as needed */
  margin-right: 13px;
}

.compact-perfume-image {
  width: auto; /* Adjust the size as needed */
  height: 78px; /* Adjust the size as needed */
  object-fit: cover;
  margin: 0;
  padding: 0
}

.compact-content {
  flex: 1;
}

.mobile-scent-types-compact {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5px;
  margin-bottom: 15px;
}
  
.mobile-chip-custom-compact {
  font-size: 1em; 
  color: white; 
  min-width: 40px !important;
  text-align: center; 
  justify-content: center;
  border-radius: 10px !important;
}

  
  
/* Rating bars*/  
  
  .rating-row {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }  
  
  /* Adjustments to the column labels to remove default padding and margins */
  .rating-label, .rating-bar-container {
    padding: 0 !important; /* Remove padding inside the columns */
    margin: 0 !important; /* Remove any margin that might be adding space around columns */
    margin-top: 3px !important;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
  }
  
  .rating-label {
    font-weight: normal; /* Adjust font weight as needed */
    text-align: right; /* Right-align the label text */
    padding-right: 10px; /* Space between the text and the bar */
  }
  
  .rating-bar-container {
    display: flex;
    align-items: center; /* Align items vertically in the center */ 
    margin-bottom: 0 !important;
  }
  
.rating-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

}
  

  

   /* V-Chips with notes for horizontal scrolling */
  
  
.mobile-chip-strong-notes {
  font-size: 1em !important; /* Set this to the font size of your grey boxes */
  color: #000000 !important; /* Black text color for strong notes */
  background-color: #efefef !important; /* Darker grey for strong notes */
  text-align: center; /* Horizontally center the text */
  justify-content: center; /* Center content within the flex container */
  border: none; /* In case you don’t want any border */
  height: 35px; /* Height to match your grey boxes, if they are 35px */
  min-width: initial !important; /* Override any previous minimum width */
  margin-right: 8px; /* Adjust spacing between chips as needed */
  margin-bottom: 0; /* Adjust bottom margin if necessary */
  flex-shrink: 0; /* Prevent chips from shrinking */
  user-select: none  !important; /* Prevent text selection */
  border-radius: 10px !important;
  /*font-weight: bold;  Bold text for strong notes */
}

.mobile-chip-medium-notes {
  font-size: 1em !important; /* Set this to the font size of your grey boxes */
  color: #535353 !important; /* Medium grey text color for medium notes */
  background-color: #f7f7f7 !important; /* Medium grey for medium notes */
  text-align: center; /* Horizontally center the text */
  justify-content: center; /* Center content within the flex container */
  border: none; /* In case you don’t want any border */
  height: 35px; /* Height to match your grey boxes, if they are 35px */
  min-width: initial !important; /* Override any previous minimum width */
  margin-right: 8px; /* Adjust spacing between chips as needed */
  margin-bottom: 0; /* Adjust bottom margin if necessary */
  flex-shrink: 0; /* Prevent chips from shrinking */
  user-select: none  !important; /* Prevent text selection */
  border-radius: 10px !important;
}

.mobile-chip-weak-notes {
  font-size: 1em !important; /* Set this to the font size of your grey boxes */
  color: #838383 !important; /* Lighter grey text color for weak notes */
  background-color: #fbfbfb !important; /* Lighter grey for weak notes */
  text-align: center; /* Horizontally center the text */
  justify-content: center; /* Center content within the flex container */
  border: none; /* In case you don’t want any border */
  height: 35px; /* Height to match your grey boxes, if they are 35px */
  min-width: initial !important; /* Override any previous minimum width */
  margin-right: 8px; /* Adjust spacing between chips as needed */
  margin-bottom: 0; /* Adjust bottom margin if necessary */
  flex-shrink: 0; /* Prevent chips from shrinking */
  user-select: none  !important; /* Prevent text selection */
  border-radius: 10px !important;
}
  
 

  
   .mobile-chip-custom-notes {
    font-size: 1em !important; /* Set this to the font size of your grey boxes */
    color: white; /* White text color for contrast */
    background-color: #efefef !important; /* The specified grey color */
    text-align: center; /* Horizontally center the text */
    justify-content: center; /* Center content within the flex container */
    border: none; /* In case you don’t want any border */
    height: 35px; /* Height to match your grey boxes, if they are 35px */
    min-width: initial !important; /* Override any previous minimum width */
    margin-right: 8px; /* Adjust spacing between chips as needed */
    margin-bottom: 0; /* Adjust bottom margin if necessary */
    flex-shrink: 0; /* Prevent chips from shrinking */
    user-select: none !important; /* Prevent text selection */
    border-radius: 10px !important;
  }
  
  .scrollable-chips-container {
    overflow-x: auto; /* Activates horizontal scrolling */
    white-space: nowrap; /* Ensures that the contents do not wrap */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
    margin-right: 13px;
    margin-left: 13px;
    margin-bottom: 5px;
    user-select: none  !important; /* Prevent text selection */
  }
  
  .scrollable-chips-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  .scrollable-chips-container:active {
  cursor: grabbing; /* Show grabbing cursor when active */
}

.scrollable-chips-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.mobile-chip-custom-notes {
  cursor: pointer; /* Change cursor to pointer when hovering over chips */
}
  
  .chips-row {
    display: flex; /* Flex container */
    flex-wrap: nowrap; /* Prevents wrapping of items */
    height: 35px;
    margin-left: 0px;
    user-select: none; /* Prevent text selection */    
  }


  /* For non-mobile devices */

  .desktop-chips-container {
    overflow-x: auto; /* Activates horizontal scrolling */
    overflow-y: hidden;
    white-space: nowrap; /* Ensures that the contents do not wrap */
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */
    align-items: flex-start; /* Align items at the start of the flex line */
    margin-right: 13px;
    margin-left: 13px;
    margin-bottom: 5px;
    height: 70px;
    user-select: none; /* Prevent text selection */
  }

  .desktop-chips-container::-webkit-scrollbar {
    display: none; /* Hides scrollbar in WebKit browsers */
  }

  .chips-row-desktop {
    flex-wrap: nowrap; /* Ensures chips stay in a single line */
    margin-bottom: 7px;
    user-select: none; /* Prevent text selection */
  }


/* For brands filter section */

  .triangle-right {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black; /* Adjust color as necessary */
  transform: rotate(-90deg);
  vertical-align: middle;
  margin-bottom: 1px;
  margin-left: 3px
}

.triangle-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black; /* Adjust color as necessary */
  vertical-align: middle;
  margin-bottom: 1px;
  margin-left: 3px
}


   /* Fixed Header Searchterm */
   .search-query-display {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -70%);
  font-size: 14px; /* Adjust font size as needed */
  color: darkgrey; /* Set font color to dark grey */
   /* Make font italic */
  padding-bottom: 5px;
}


   /* Floating Buttons */
  
  .mobile-fab {
    border-radius: 10px; /* More rounded edges */
    padding: 0 16px; /* Increase padding horizontally */
    height: 40px !important; /* Decrease height */
    min-width: 40px; /* Allow the button to shrink based on content width */
    width: auto !important; /* Adjust width based on content */
    background-color: #000;
    
  }
  
  .mobile-fab .v-icon {
    font-size: 16px; /* Adjust icon size if necessary */
  }
  
  
  /*Filter Settings Icon*/
  
  .mobile-fab-settings {
    border-radius: 10px; /* More rounded edges */
    padding: 0 16px; /* Increase padding horizontally */
    height: 40px !important; /* Decrease height */
    min-width: 40px; /* Allow the button to shrink based on content width */
    width: auto !important; /* Adjust width based on content */
    display: inline-flex; /* Use inline-flex to allow for auto width */
    justify-content: center; /* Center the icon */
    align-items: center; /* Align icon vertically */
  }
  
  .mobile-fab-settings .v-icon {
    font-size: 16px; /* Adjust icon size if necessary */
  }

  .transparent-btn {
  background-color: #e6e6e64a !important; /* Semi-transparent background color */
  box-shadow: none !important; /* Remove the shadow */
  border: none !important; /* Remove any border */
  padding: 0; /* Add some padding to make the button smaller */
  min-width: auto !important; /* Remove the minimum width */
  max-width: 42px; /* Ensure the button only takes up as much space as needed */
  max-height: 42px;
  height: auto; /* Ensure the button only takes up as much space as needed */
  border-radius: 50%; /* Keep the border-radius for rounded corners */
}

.transparent-btn v-icon {
  color: #000; /* Set the icon color to black or whatever you prefer */
  font-size: 28px; /* Adjust the icon size if needed */
}

  
  
  /* Margin between grey boxes */
  
  .mobile-rating-box {
    background-color: #efefef; /* Grey background */
    padding: 28px; /* Padding inside the box */
    padding-top: 33px; 
    padding-right: 33px; 
    border-radius: 10px; /* Rounded corners */
    border-color: #efefef;
    margin-bottom: 1px !important;
    max-height: 130px !important;
    
  }

  .mobile-text-box {
    background-color: #efefef;
    padding: 15px;
    border-radius: 10px;
    border-color: #efefef;
  }

  .flex-text-box {
    background-color: #efefef;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    border-color: #efefef;
    height: 98px;
    line-height: 1.6;
  }
  
  
  /* Reduce the bottom margin of each box */
  .mobile-text-box, .mobile-rating-box {
    margin-bottom: 8px;
  }  

  
 /*FILTER STYLE*/

 .filter-options-slider {
    padding-right: 7%; 
    padding-left: 7%;
    height: 80px;
    margin-top: 15px;
    margin-bottom: 25px; 
}

/* grey rail */
.vue-slider-rail {
    height: 2px; 
    background-color: #ccc; 
    border-radius: 2px;
}

/* small marks on grey rail */
.vue-slider-mark {
    width: 2px !important; 
    height: 2px !important; 
    background-color: black; 
    border-radius: 50%; 
    transform: translateY(-50%);
}

/* black rail for selected range */
.vue-slider-process {
    background-color: rgb(0, 0, 0); /* Dark fill color */
    height: 3.5px !important; /* Keep consistent with the rail's height */
    border-radius: 2px; /* Rounded edges for the process */
}

.vue-slider-dot {
    width: 9px !important; /* Set the handle size */
    height: 9px !important;
    background-color: black;
    border-radius: 50%;
    box-shadow: none; 
    cursor: pointer;
    padding: 3px;
}

.vue-slider-dot::after {
    content: "";
    position: absolute;
    top: -20px !important; /* Extend the area beyond the visible dot */
    left: -20px !important;
    right: -20px !important;
    bottom: -20px !important;
    border-radius: 50%;
    background: transparent; /* Invisible but clickable */
}

.vue-slider-mark-label {
    font-family: 'Roboto', sans-serif; /* Roboto is commonly used in Vuetify */
    font-size: 14px; /* Typical size for button text */
    font-weight: 500; /* Medium weight for a bit of emphasis, similar to Vuetify's buttons */
    color: #333; /* Ensuring text color matches the button text color */
    text-transform: uppercase; /* Maintaining uppercase styling */
    letter-spacing: 0.5px; /* Adding letter spacing to match button styling */
    margin-top: 10px; /* Space above the labels for clarity */
    text-align: center; /* Ensure labels are centered under the marks */
}



 .filter-drawer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%; /* Adjust the width as needed */
    height: 93%; /* Adjust the height as needed */
    background-color: white;
    transition: transform 0.29s ease-in-out;
    transform: translateY(100%); /* Start hidden below the screen */
    z-index: 1000; /* Ensure it's above other content */
    padding-left: 12px;
    padding-top: 20px;
    padding-right: 3px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
 
  .flex-filter-drawer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 730px;
    width: calc(100% - 200px); 
    margin: auto;
    height: calc(100% - 80px); /* Subtract 30px from the total width for margins */
    padding: 20px 15px 5px; /* Adjusted padding for top, horizontal, and bottom */
    transition: transform 0.29s ease-in-out;
    transform: translateY(120%); /* Start hidden below the screen */
    z-index: 1000;
    background-color: white;
    border-radius: 10px;
  }
  
  .filter-drawer-open {
    transform: translateY(0); /* Slide into view */
  }  
  
  .filter-drawer-content {
    overflow-y: auto; /* Enables vertical scrolling within the drawer */
    overflow-x: hidden;
    height: calc(100% - 60px); /* Adjust the height to account for the footer */
    padding-bottom: 60px; /* Same as the footer's height */
    padding-top: 15px;
    padding-left: 4px;
    padding-right: 12px
  }

  .filter-options {
    padding-bottom: 45px;
    padding-top: 7px;
    padding-left: 1px;
  }  
  
  .filter-drawer-footer {
    display: flex; /* Ensures the buttons are aligned with flexbox */
    justify-content: space-between; /* Places one button at each end */
    align-items: center; /* Centers the buttons vertically */
    position: absolute;
    width: 100%; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white; /* Adjust the background as needed */
    padding: 16px; /* Adjust padding to control spacing from the edges */
    z-index: 1100;
    border-top: 1px solid #ccc; /* Adds a thin grey line at the top */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
    .sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    left: 0;  /* Ensures alignment starts from the very left */
    right: 0; /* Ensures alignment extends to the very right */
    z-index: 1000; 
    width: 100%; 
    height: 21px;  
    padding-right: 0;
    border-bottom: 1px solid #ccc; /* Adds a thin grey line at the bottom */
    box-sizing: border-box; /* Ensures the border is included within the width */
    padding: 0;  /* Removes any padding which might be affecting width */
    margin-left: -15px; /* Adjust based on parent padding */
    margin-right: -15px; /* Adjust based on parent padding */
    width: calc(100% + 30px); /* Compensates for negative margins */   
  }
  
  .sticky-header .close-btn-container {
    position: absolute;
    right: 22px;
    top: -17px;
    padding: 0px; 
  }
  
  .no-scroll {
    overflow: hidden;
    width: 100%;
    position: fixed; 
  }
  
    
  /* Styling for the buttons to ensure they do not take full width */
  .filter-drawer-footer .v-btn {
    padding: 0 16px; /* Horizontal padding inside the button */
    min-width: 40px; /* Minimum width of the button */
    width: 120px; /* Allows the button to grow with content up to its content width */
    height: 40px;
    border-radius: 10px; /* Rounded corners for the button */
  
  }
  
  .left-button, .right-button {
    flex: 0 0 auto; /* Buttons of Filter Drawer: not grow, does not shrink, and is automatically sized */
  }
  
  /* Remove any unnecessary width settings */
  .left-button {
    margin-left: 0; /* Ensures button is at the very left */
    background-color: white !important; /* Sets the background to white */
    color: grey; /* Sets the text color to grey */
    text-decoration: underline; /* Underlines the text */
    box-shadow: none !important; /* Removes any box shadows */
    border: none !important; /* Removes any borders, if present */
    /* Additional styling to ensure alignment and padding */
    justify-content: center;
    align-items: center;
    padding: 0 16px; /* Adjust padding as necessary */
    min-width: 40px; /* Minimum width to maintain usability */
    width: auto; /* Width adjusts to content */
    border-radius: 10px; /* Optional: removes any border radius if you want a sharp-corner button */
  }

   /* Remove any unnecessary width settings */
   .left-button-info {
    margin-left: 0; /* Ensures button is at the very left */
    background-color: white !important; /* Sets the background to white */
    color: grey; /* Sets the text color to grey */
    box-shadow: none !important; /* Removes any box shadows */
    border: none !important; /* Removes any borders, if present */
    /* Additional styling to ensure alignment and padding */
    justify-content: center;
    align-items: center;
    padding: 0 16px; /* Adjust padding as necessary */
    min-width: 40px; /* Minimum width to maintain usability */
    width: auto; /* Width adjusts to content */
    border-radius: 10px; /* Optional: removes any border radius if you want a sharp-corner button */
  }
  
  .right-button {
    margin-right: 0; /* Ensures button is at the very right */
    min-width: 205px !important; /* Minimum width to maintain usability */
  }
  
  /* Add spacing in the number of results  */
  .number-spacing {
      margin: 0 3.5px; /* Adjust the space as needed */
  }
  

  .filter-section {
    max-width: 730px;
  }
  
  /* Base class for all buttons */
  .filter-option-button {
    background-color: #ffffff;
    border: 1px solid rgb(198, 198, 198); 
    border-radius: 10px; 
    margin-right: 8px;
    text-align: center; 
    
  }
  
  /* Class for selected button state */
  .filter-option-button-selected {
    background-color: #242424;
    color: white !important;
    border: 1px solid #242424;
    border-radius: 10px;
  }
  
  .filter-option-button-excluded {
    background-color: white;
    border-radius: 10px; 
    border: 1px solid rgba(0, 0, 0, 0) !important; 
  }
  
  .filter-options .v-btn:disabled {
    opacity: 1 !important; 
    color: black !important; 
  
  }
  


/*Filter Buttons in Mobile View*/
  
.mobile-filter-option-button {
    background-color: #ffffff;
    margin: -0.5px;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 10px; 
    margin-right:6px; 
    text-align: center;
    font-size: 13px !important; /* Decrease the font size */
    padding: 3px 7px !important;
    max-height: 33px;
  }
  
  
  .mobile-filter-option-button-selected {
    background-color: #242424;
    color: white !important;
    border: 1px solid #242424; /* Default border for unselected */
    border-radius: 10px; 
  }
  
  .mobile-filter-option-button-excluded {
    background-color: white !important;
    color: #757575; /* Dimmed text color for excluded options */
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0) !important; 
  }
  
  
  
/* Others */
   
.no-results-container {
  display: flex;
  flex-direction: column; /* Aligns children (text and buttons) vertically */
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 250px; /* Adjust height as necessary */
}

.no-results-message {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #c0c0c0;
  font-style: italic;
  margin-bottom: 20px; /* Adds space between the message and the buttons */
}

  
  .floating-letter {
  position: absolute;
  top: -6px;
  right: 12px;
  font-size: 15px;
  font-weight: bold;
  background: white;
  padding: 7px;

}

.search-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #efefef;
  padding: 13px;
  border-radius: 10px;
  margin: 16px 12px;
}

.search-item.non-mobile {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; /* Align items at the start of the flex line */
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 8px;
  border: 1.7px solid #262626;
  border-radius: 10px;
  min-height: 38px !important;
  width: 155px !important;
  margin-bottom: 10px;
  margin-top: 7px; /* Adjust this value to align vertically */
}

.search-container .mdi {
  font-size: 18px;
  color: #888;
  margin-right: 10px;
}

.search-container span {
  color: #000000;
}

.search-item p {
  margin: 0;
  padding-left: 10px;
}

.search-item.non-mobile p {
  margin: 0;
  padding-left: 10px;
  margin-left: 20px;
}

.underline {
    text-decoration: underline;
  }

</style>


<style scoped>

/* Pagination */

.custom-pagination >>> .v-pagination__item {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the color as needed */
}

.custom-pagination >>> .v-pagination__item--active {
  background-color: #000 !important; /* Active item background color */
  color: #ffffff; /* Active item text color */
  border-radius: 10px !important
}

.custom-pagination >>> .v-pagination__item--disabled {
  color: #b0b0b0; /* Adjust the disabled item color as needed */
}

.custom-pagination >>> .v-pagination__more {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the more button color as needed */
}

.custom-pagination >>> .v-pagination__navigation {
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #000; /* Adjust the arrow color as needed */
}

.custom-pagination >>> .v-pagination__navigation--disabled {
  color: #b0b0b0; /* Adjust the disabled arrow color as needed */
}


/* Keeps the chips color the same as non-hovered/non-focused */ 


.scrollable-chips-container .chips-row ::v-deep .v-chip {
  &:hover, &:focus {
    background-color: inherit !important; /* Keeps the background color the same as non-hovered/non-focused */    
  }
}

.scrollable-chips-container .chips-row ::v-deep .mobile-chip-strong-notes {
  &:hover, &:focus {
    background-color: #f8f8f8 !important; /* Keeps the background color the same as non-hovered/non-focused */    
  }
}


</style>