<template>
    <div class="legal-info">

      <h3>Feedback</h3>
      <br>
      <div class="email-section">
        <v-icon color="black" class="mr-2">mdi-email-outline</v-icon>
        <a href="mailto:mail@perfumeprophet.com" class="email-link">Feel free to write me an e-mail</a>
      </div>
      <br><br>

      <h3>Disclaimer</h3>
      <br>
      <p><strong>1. Content Disclaimer:</strong></p>
      <p>
        The information provided by Perfume Prophet on this application is for general informational purposes only. All information on the application is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the application.
      </p>
      
      <p><strong>2. External Links Disclaimer:</strong></p>
      <p>
        The application may contain (or you may be sent through the application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the application or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
      </p>
      
      <p><strong>3. Personal Opinions Disclaimer:</strong></p>
      <p>
        Ratings and evaluations presented in this application are based on personal opinions from forums or individual users and do not reflect the views or opinions of Perfume Prophet. We are not liable for any errors or omissions in these evaluations or for any losses or damages incurred as a result of the use of this information.
      </p>
      
      <p><strong>4. No Professional Advice:</strong></p>
      <p>
        The information provided on this application is not intended to be a substitute for professional advice. Always seek the advice of a qualified professional with any questions you may have regarding a particular matter. Never disregard professional advice or delay in seeking it because of something you have read on this application.
      </p>
      
      <p><strong>5. Limitation of Liability:</strong></p>
      <p>
        In no event shall Perfume Prophet, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the application; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the application; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our application by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the application; and/or (vi) the defamatory, offensive, or illegal conduct of any third party. In no event shall Perfume Prophet be liable for any claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding the amount you paid to Perfume Prophet hereunder or $100.00, whichever is greater.
      </p>
      
      <p><strong>6. Changes to This Disclaimer:</strong></p>
      <p>
        We may update our Disclaimer from time to time. We will notify you of any changes by posting the new Disclaimer on this page. You are advised to review this Disclaimer periodically for any changes. Changes to this Disclaimer are effective when they are posted on this page.
      </p>

      <h3>Impressum</h3>
      <br>
      <p><strong>Owner and Operator:</strong></p>
      <p>Perfume Prophet</p>
      <p>Lucas Kutscher</p>
      <p>Cologne, Germany</p>
      <p>Email: mail@perfumeprophet.com</p>
      <p>Website: perfumeprophet.com</p>
    
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'LegalInfo',
  };
  </script>
  
  <style scoped>
  .legal-info {
    padding: 20px;
    color: rgb(24, 24, 24);
    border: 0px solid #ddd;
    border-radius: 10px;
    margin-top: 5px;
  }
  .legal-info h4 {
    margin-top: 0;
  }

  .email-section {
  display: flex;
  align-items: center;
  margin-bottom: 16px; /* Add space below the section */
}

.email-link {
  color: black; /* Set text color */
  text-decoration: none; /* Remove underline from the link */
  font-weight: bold; /* Make the text bold */
  font-size: 16px; /* Adjust font size as needed */
}

  </style>
  