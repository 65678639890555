<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
    <v-main>
      <keep-alive include="ExplorePage, ExploreAllPage">
      <router-view />
    </keep-alive>
    </v-main>

    <!-- Bottom Navigation Bar -->
<v-bottom-navigation
  v-if="toolbarVisible && showNavBar"
  app
  fixed
  grow
  class="bottom-nav-bar"
>
  <!-- Inspiration Button -->
  <v-btn
    @click="navigateToLastInspirationRoute"
    class="nav-btn inspiration-btn"
    :class="{ active: isInspirationActive }"
  >
    <v-icon style="font-size: 27px;">{{ isInspirationActive ? 'mdi-lightbulb' : 'mdi-lightbulb-outline' }}</v-icon>
    <span style="display: block; height: 3px; color: white;"> ...</span>
    <span>Inspiration</span>
  </v-btn>

  <!-- Search Button -->
  <v-btn
    :to="{ path: '/perfumes' }"
    class="nav-btn"
    :class="{ active: $route.path === '/perfumes' }"
  >
    <v-icon style="font-size: 27px;">{{ $route.path === '/perfumes' ? 'mdi-magnify' : 'mdi-magnify' }}</v-icon>
    <span style="display: block; height: 3px; color: white;"> ...</span>
    <span>Search</span>
  </v-btn>

  <!-- Favorites Button -->
  <v-btn
    :to="{ path: '/favorites' }"
    class="nav-btn"
    :class="{ active: $route.path === '/favorites' }"
  >
    <v-icon style="font-size: 27px;">{{ $route.path === '/favorites' ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
    <span style="display: block; height: 3px; color: white;"> ...</span>
    <span>Favorites</span>
  </v-btn>

      <!-- Info Button -->
    <v-btn
      @click="toggleInfoDialog"        
      class="nav-btn"      
    >
    <v-icon style="font-size: 27px; color: #606060;">mdi-information-outline</v-icon>
      <span style="display: block; height: 3px; color: white;"> ...</span> <!-- Add a small gap -->
      <span style="color: #606060">Info</span>
    </v-btn>
</v-bottom-navigation>


<!-- Info Dialog -->
<v-dialog
      v-model="isInfoDialogOpen"
      width="650"
      class="custom-dialog"
    >
      <v-card class="dialog-card">
        <!-- Header with close button -->
        <v-card-title class="dialog-header">
          <v-btn icon @click="isInfoDialogOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Dialog content -->
        <v-card-text class="dialog-content">
          <LegalInfo />
        </v-card-text>

        <!-- Footer with Ok button -->
        <v-card-actions class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn color="black" dark class="dialog-button" @click="isInfoDialogOpen = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <cookie-consent />
  </v-app>
</template>


<script>
import LegalInfo from './components/LegalInfo.vue';
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  data() {
    return {
      isInfoDialogOpen: false,
      scrollY: 0, // Track the current scroll position
      toolbarVisible: true,
      lastScrollY: 0,
      buttonBottom: '85px',
      isMobile: false,
      savedInspirationScrollPos: 0, // Save scroll position for Inspiration
    };
  },

  components: {
    LegalInfo,
    CookieConsent
  },
  
  computed: {
    isInspirationActive() {
      return this.$route.path === '/explore' || this.$route.path === '/explore_all';
    }
  },
  methods: {
    navigateToLastInspirationRoute() {
      const lastRoute = this.loadLastInspirationRoute();
      if (this.$route.path !== lastRoute) {
        this.$router.push({ path: lastRoute });
      }
    },
    toggleInfoDialog() {
      this.isInfoDialogOpen = !this.isInfoDialogOpen;
    },
    handleScroll() {
      const currentScrollY = window.scrollY;

      // Save the scroll position for Inspiration routes
      if (this.$route.path === '/explore' || this.$route.path === '/explore_all') {
        this.savedInspirationScrollPos = currentScrollY;
      }

      if (this.$route.path === '/explore' || this.$route.path === '/explore_all') {
        this.toolbarVisible = true;
      } else {
        if (this.isMobile) {
          if (currentScrollY > this.lastScrollY && currentScrollY > 450) {
            this.toolbarVisible = false;
          } else if (currentScrollY < this.lastScrollY || currentScrollY <= 450) {
            this.toolbarVisible = true;
          }
        }
      }

      this.lastScrollY = currentScrollY;
      this.scrollY = currentScrollY;
    },
    onResize() {
      this.isMobile = window.innerWidth < 750;
      this.$forceUpdate();
    },
    updateNavBarVisibility() {
      const allowedRoutes = ['/explore', '/explore_all', '/perfumes', '/favorites'];
      this.showNavBar = allowedRoutes.includes(this.$route.path);
    },
    saveLastInspirationRoute(route) {
      if (route.path === '/explore' || route.path === '/explore_all') {
        localStorage.setItem('lastInspirationRoute', route.path);
      }
    },
    loadLastInspirationRoute() {
      const lastRoute = localStorage.getItem('lastInspirationRoute');
      return lastRoute ? lastRoute : '/explore'; // Default to '/explore'
    },
    restoreScrollPosition() {
      if (this.$route.path === '/explore' || this.$route.path === '/explore_all') {
        this.$nextTick(() => {
          window.scrollTo(0, this.savedInspirationScrollPos);
        });
      }
    },
  },
  mounted() {
  this.onResize();
  window.addEventListener('resize', this.onResize);
  window.addEventListener('scroll', this.handleScroll);
  this.updateNavBarVisibility();

  // Restore scroll position if navigating back to an Inspiration route
  if (this.isInspirationActive) {
    this.restoreScrollPosition();
  }
},
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleScroll);
  },

  
watch: {
  $route(to) {
    this.updateNavBarVisibility();
    this.saveLastInspirationRoute(to);

    // Restore the scroll position if navigating back to an Inspiration route
    if (this.isInspirationActive) {
      this.restoreScrollPosition();
    }
  }
},
};



</script>

<style>
.v-application {
  background-color: var(--v-background-base) !important;
}

.bottom-nav-bar {
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  z-index: 25;
  height: 74px !important; /* Height adjustment */
  z-index: 5 !important ;
}

.nav-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #373737 !important;
  background-color: white !important;
  margin-top: 0px; /* Adjust to 15px in Dev Mode, and 0px for prod*/
  z-index: 5 !important ;
}


.nav-btn.active {
  color: black !important; /* Set text and icon color to black */
  font-weight: bold !important; /* Make the text bold */
  z-index: 10 !important ;
}

.nav-btn.active::after {
  content: "" !important;  /* Empty content for the line */
  position: absolute !important; /* Position absolute to the button */
  bottom: 2px!important; /* Position the line below the button */
  left: 50% !important; /* Start positioning from the middle */
  transform: translateX(-50%) !important; /* Center the line horizontally */
  width: 75% !important; /* Set the width of the line */
  height: 3px !important; /* Thickness of the line */
  color: black !important; 
  background-color: black !important; /* Color of the line */
  z-index: 15 !important ;
}

/* Only apply styles to the Inspiration button when active */
.nav-btn.inspiration-btn.active .v-icon {
  color: black !important; /* Change icon color to black */
}

.nav-btn.inspiration-btn.active span {
  color: black !important; /* Change text color to black */
}


.nav-btn span {
  font-size: 12px; /* Text size remains the same */
}

.v-bottom-navigation__btn {
  padding: 0 !important;
}

/* Dialog Card Styling */
.dialog-card {
  border-radius: 10px;
  overflow: hidden;
}

/* Header Styling */
.dialog-header {
  align-items: center;
  background-color: white;
  padding: 10px 20px !important;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Content Styling */
.dialog-content {
  padding: 4px !important;
  color: black;
  font-size: 16px; /* Larger text */
  line-height: 1.5; /* Better readability */
}

/* Footer Styling */
.dialog-footer {
  border-top: 1px solid #ccc;
  padding: 10px 20px !important;
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

/* Button Styling */
.dialog-button {
  border-radius: 10px;
}

/* Customize the dialog itself */
.custom-dialog .v-dialog {
  padding: 20px;
}

/* Customize the backdrop (overlay) */
.custom-dialog .v-overlay__scrim {
  background-color: rgba(0, 0, 0, 0.4);
}

/* Ensure the dialog is centered properly */
.custom-dialog .v-dialog__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-main {
  padding-bottom: 74px; /* Adjust padding to ensure content is not hidden behind the bottom navigation */
}

</style>