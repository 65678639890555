export function sortPerfumes(perfumes, currentSort, priceToNumber, searchQuery) {


  if (currentSort) {
    console.log('Sorting based on user selection');  // Debugging line
    switch (currentSort) {
      case 'name':
        perfumes.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'brand':
        perfumes.sort((a, b) => a.brand.localeCompare(b.brand));
        break;
      case 'price':
        perfumes.sort((a, b) => priceToNumber(a.price) - priceToNumber(b.price));
        break;
      default:
        break;
    }
  } else if (searchQuery && searchQuery.trim()) {
    // Sorting by relevance if there's a search query and no sort selected
  
    perfumes.sort((a, b) => b.score - a.score);
  } else {
    
    // If no search query and no sort option, do nothing to retain the default shuffled order
  }

  
  return perfumes;
}


  
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  