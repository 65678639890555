// src/utils/colorUtils.js

export function getScentTypeColor(type) {
    const colorMap = {
      'aromatic': '#556B2F', 
      'powdery': '#D3B4AD', 
      'creamy': '#8B7E66', 
      'aquatic': '#4169E1', 
      'fresh': '#1E90FF',
      'sweet': '#C71585', 
      'flowery': '#BA55D3', 
      'fruity': '#FF7F50', 
      'synthetic': '#696969',
      'woody': '#8B4513', 
      'leathery': '#800020',
      'citric': '#FFBF00', 
      'green': '#32CD32', 
      'gourmand': '#DEB887', 
      'animalic': '#4E0000', 
      'oriental': '#B22222', 
      'mineral': '#607D8B',
      'smoky': '#1C1C1C', 
      'default': '#808080'
    };
    return colorMap[type.toLowerCase()] || colorMap['default'];
  }
  