<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
    <v-main>
      <v-container :class="isMobile ? 'startpage-container-mobile' : 'startpage-container-flex'">     
          
          <v-col cols="12" class="text-left">
              <h1 class="app-title">
                <span class="underline">Perfume</span> Prophet.
              </h1>
            </v-col>                
        
         
          <v-col cols="12" class="text-left">
            <h1 class="daily-header">
              Hey, how would you like to smell today?
            </h1>
          </v-col>


        <!-- Display 6 random tiles -->
        <v-row>
          <v-col
            v-for="(item, index) in displayedCards"
            :key="index"            
            cols="12"
            xs="2"
            sm="6"
            md="4"
            lg="4"
            class="d-flex"
          >
            <v-card 
              @click="navigateToPerfumes(item.note1, item.note2, item.type1, item.type2, item.gender, item.brand, 
                                        item.intensity, item.longevity, item.price)"
              class="custom-card"
            >
              <v-img
                :src="getImage(item.image)"
                height="360px"
              ></v-img>
              <v-card-title class="custom-card-title">{{ item.title }}</v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <!-- "Show all impressions" button -->
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn class="button" @click="navigateToAllImpressions">
              <span>Get more Inspiration</span>
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>


          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'; // Import axios for HTTP requests

export default {
  name: 'ExplorePage',
  data() {
    return {
      exploreData: [], // Array to hold explore data
      imageMap: {}, // Object to map image names to image URLs
      displayedCards: [], // Array to hold the 6 random tiles
      defaultImage: require('@/assets/explore_images/explore_image1.webp'), // Default image
    };
  },
  methods: {
    async fetchExploreData() {
      const cacheKey = 'exploreDataCache';
      const cacheTimestampKey = 'exploreDataTimestamp';
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      const now = new Date().getTime();

      const cachedData = JSON.parse(localStorage.getItem(cacheKey));
      const cachedTimestamp = localStorage.getItem(cacheTimestampKey);

      if (cachedData && cachedTimestamp && (now - cachedTimestamp) < oneDay) {
        // Use cached data if it exists and is less than one day old
        this.displayedCards = cachedData;
      } else {
        // Fetch new data and cache it
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/explore`);
          const filteredData = response.data.filter(item => item.title !== 'title');
          const randomData = filteredData.sort(() => Math.random() - 0.5).slice(0, 6);
          
          this.displayedCards = randomData;
          this.exploreData = filteredData;

          // Cache the data and timestamp
          localStorage.setItem(cacheKey, JSON.stringify(randomData));
          localStorage.setItem(cacheTimestampKey, now);
        } catch (error) {
          console.error('Error fetching explore data:', error);
        }
      }
    },

    loadImages() {
      const context = require.context('@/assets/explore_images', false, /\.webp$/);
      context.keys().forEach((key) => {
        const imageName = key.match(/[^/]+(?=\.webp$)/)[0]; // Extract the image name without the extension
        this.imageMap[imageName] = context(key);
      });
    },

    getImage(imageName) {
      // Return the mapped image or the default image if not found
      return this.imageMap[imageName] || this.defaultImage;
    },

    onResize() {
  this.isMobile = window.innerWidth < 750; // Define mobile Device
  this.$forceUpdate();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    // other cleanup logic...
  },
   
  navigateToPerfumes(note1, note2, type1, type2, gender, brand, intensity, longevity, price) {
  const query = {};

  // Build the search query from note1 and note2
  const searchTerms = [note1, note2].filter(Boolean).join(' '); // Combine notes without converting to lowercase

  if (searchTerms) {
    query.search = searchTerms;
  }

  // Format types with capitalized first letter
  const types = [];
  if (type1) types.push(this.capitalizeFirstLetter(type1));
  if (type2) types.push(this.capitalizeFirstLetter(type2));
  if (types.length) query.type = types.join(',');

// Add gender to the query if it exists
  // Add gender to the query if it exists
  if (gender) {
    query.gender = gender.toLowerCase(); // Ensure gender is lowercase in the URL
  }
  // Directly use the brand as it appears in the datasource
  if (brand) {
    query.brand = brand.split(',').map(b => b.trim()).join(',');
  }

    // Add intensity to the query if it exists
    if (intensity) {
    query.intensity = intensity.split(',').map(i => i.trim()).join(',');
  }

  // Add longevity to the query if it exists
  if (longevity) {
    query.longevity = longevity.split(',').map(l => l.trim()).join(',');
  }

  // Add price to the query, ensuring it includes both min and max
  if (price) {
    const priceValues = price.split(',').map(p => parseInt(p.trim(), 10));
    const minPrice = Math.min(...priceValues);
    const maxPrice = Math.max(...priceValues);
    query.price = `${minPrice},${maxPrice}`;
  }

   // Debug output to check query parameters
   console.log('Query parameters:', query);

  this.$router.push({
    name: 'PerfumeDataTable',
    query
  });
},

  capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },


    navigateToAllImpressions() {
      this.$router.push({ name: 'ExploreAllPage' });
    },




  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.fetchExploreData(); // Load data when component mounts
    this.loadImages(); // Load images when component mounts
  }

  

  
};
</script>

<style>
.startpage-container-mobile {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  padding-top:3px;
  max-width: 100%; /* Ensures the container does not extend beyond the viewport */
}

.startpage-container-flex {
  padding-left: 33px;
  padding-right: 25px;
  padding-bottom: 5px;
  padding-top:4px;
  max-width: 100%; /* Ensures the container does not extend beyond the viewport */
}

.daily-header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 6px;
  color: #000;
  text-align: left;
  line-height: 1.25;
  max-width: 100%
}

.custom-card-title {
  font-size: 1.3rem; /* Adjust the font size as needed */
  font-weight: bold; /* Make the font bold if needed */
  text-align: center; /* Center the title text if desired */
  color: #131313; /* Adjust the color as needed */
  white-space: normal; /* Prevents awkward word splitting */
  word-break: break-word; /* Ensures words only break at whole-word boundaries */
  max-width: 100%
}

   
.app-title {
  font-size: 1.15rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #000;
  max-width: 100%
}


.main-title {
  font-size: 2.7rem;
  font-weight: bold;
  margin-bottom: 55px;
  line-height: 1.22;
  max-width: 100%
}

.description {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  line-height: 1.4;
}

.custom-card {
  width: 100%;
  max-width: 480px;
  min-width: 250px;
  margin: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 100%
}

.button {  
  border-radius: 10px;
  min-height: 40px;
  background-color: white !important; /* Semi-transparent background color */
  box-shadow: none !important;
}

</style>
