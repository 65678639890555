//Click Logo to start new search
export function deleteAllFilters(context, removeLocalStorage = false) {
  context.selectedBrand = [];
  context.selectedSillage = [1, 4];
  context.selectedScentgender = [];
  context.selectedLongevity = [1, 4];
  context.selectedPrice = [1, 5];
  context.selectedType = {};
  context.currentSort = ''; 
  context.tempSelectedBrand = []; 
  context.tempSelectedSillage = [1, 4];
  context.tempSelectedScentgender = [];
  context.tempSelectedLongevity = [1, 4];
  context.tempSelectedPrice = [1, 5];
  context.tempSelectedType = {};
  context.tempSort = '';
  context.preQuery = '';
  
  // Check if local storage should be cleared
  if (removeLocalStorage) {
    // Clear the shuffled perfumes from local storage
    localStorage.removeItem('shuffledPerfumes');
  }

  // Refetch the data, which will trigger a new shuffle
  context.fetchPerfumes();    
}


  //Filter Reset Button & Sort (for Delete all Button in Filtermenu)
  export function deleteTempFilters(context) {
    context.tempSelectedBrand = []; 
    context.tempSelectedSillage = [1, 4];
    context.tempSelectedScentgender = [];
    context.tempSelectedLongevity = [1, 4];
    context.tempSelectedPrice = [1, 5];
    context.tempSelectedType = {};
    context.tempSort = '';
  }

//Filter Reset Button & Sort when exit the filtermenu without apply
  export function resetTempFilters(context) {
    context.tempSelectedBrand = [...context.selectedBrand];
    context.tempSelectedSillage = [...context.selectedSillage];
    context.tempSelectedScentgender = [...context.selectedScentgender];
    context.tempSelectedLongevity = [...context.selectedLongevity];
    context.tempSelectedPrice = [...context.selectedPrice];
    context.tempSelectedType = { ...context.selectedType };
    context.tempSort = context.currentSort
  }



 // Remove certain filters when pressing 'Remove Filters' buttons

export function removeFilter(filterType, context) {
  switch (filterType) {
    case 'sillage':
      context.selectedSillage = [1, 4];
      context.tempSelectedSillage = [1, 4];
      break;
    case 'price':
      context.selectedPrice = [1, 5];
      context.tempSelectedPrice = [1, 5];
      break;
    case 'longevity':
      context.selectedLongevity = [1, 4];
      context.tempSelectedLongevity = [1, 4];
      break;
    case 'gender':
      context.selectedScentgender = [];
      context.tempSelectedScentgender = [];
      break;
    case 'brand':
      context.selectedBrand = [];
      context.tempSelectedBrand = [];
      break;
  }
  context.filterPerfumes();
}
