<template>
    <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
      <v-main>
        <v-container fluid class="d-flex align-center justify-center startpage-container">
          <v-row>
            <v-col cols="12" class="text-left">
              <h1 class="app-title">
                <span class="underline">Perfume</span> Prophet.
              </h1>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-center pr-md-10">
              <div class="image-container">
                <img src="@/assets/startpage_image.png" alt="Fragrance Image" class="rounded-image" />
              </div>
            </v-col>
            <v-col cols="12" md="6" class="text-left pl-md-10">
              <h1 class="main-title">Discover your perfect perfume</h1>
  
              <p class="description"><span class="highlight"></span> Find your dream fragrance within <span class="highlight">more than 2000 popular perfumes.</span></p>              
              <p class="description"><span class="highlight">Search and filter</span> by scent profile, notes, intensity, and more.</p>             
              <p class="description"><span class="highlight"></span>Save your favorite perfumes to your <span class="mdi mdi-heart" style="color:red;"></span> Favorites list.</p>
              <p class="description"><span class="highlight"> Get inspiration</span> and discover new perfumes that match to your personal taste.</p>
  
              <v-btn dark fab color="black" class="explore-button" @click="navigateToApp">
                <span>Explore perfumes</span>
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
  
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    name: 'StartPage',
    methods: {
      navigateToApp() {
        this.$router.push({ name: 'ExplorePage' });
      }
    }
  }
  </script>
  
  <style scoped>
  .startpage-container {
    min-height: 100vh;
    padding: 20px;
  }
  
  .rounded-image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }

  .image-container {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  }
  
  .app-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #000;
  }
  
  .main-title {
    font-size: 2.7rem;
    font-weight: bold;
    margin-bottom: 55px;
    line-height: 1.22;
  }
  
  .description {
    font-size: 1.4rem;
    margin-bottom: 20px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    line-height: 1.4;
  }
  
  .description .highlight {
    color: #000;
    background-color: rgba(255, 241, 148, 0.797);
  }

  .crossout {
  position: relative;
  color: #000;
}

.crossout::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 3.5px; /* Adjust the height to make the line thicker */
  background-color: #000; /* Same color as the text */
  z-index: 1;
}

  
  .explore-button {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 16px;
    background-color: #000 !important;
    color: #fff;
    border-radius: 10px;
    padding: 12px 24px;
    width: 234px;
    height: 45px;
  }
  
  .underline {
    text-decoration: underline;
  }
  </style>
  