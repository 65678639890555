import Vue from 'vue';
import Router from 'vue-router';
import StartPage from '@/components/StartPage.vue';
import PerfumeDataTable from '@/components/PerfumeDataTable.vue';
import ExplorePage from '@/components/ExplorePage.vue';
import ExploreAllPage from '@/components/ExploreAllPage.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'StartPage',
      component: StartPage,
    },
    {
      path: '/explore',
      name: 'ExplorePage',
      component: ExplorePage,
    },
    {
      path: '/explore_all',
      name: 'ExploreAllPage',
      component: ExploreAllPage,
    },
    {
      path: '/perfumes',
      name: 'PerfumeDataTable',
      component: PerfumeDataTable,
    },

    {
      path: '/favorites',
      name: 'FavoritesList',
      component: () => import('@/components/FavoritesList.vue'),
    },
    
  ],

  scrollBehavior(to, from, savedPosition) {
    if (to.path === '/perfumes' || to.path === '/favorites') {
      return { x: 0, y: 0 }; // Always scroll to top for /perfumes or /favorites
    }
    if (savedPosition) {
      return savedPosition; // Return to saved position if available (used when navigating back)
    }
    return { x: 0, y: 0 };
  }
 

});